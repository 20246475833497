import {Entity} from '@/app/core/entity/main.entity';
import {WritableSignal} from '@angular/core';

export class TableAction extends Entity {
  buttons: TableActionButton[];
  disabled?: WritableSignal<boolean>;
}

export class TableActionButton extends Entity {
  label: string;
  hideIfParent?: boolean = false;
  onClick: (data?: any) => void;
}
