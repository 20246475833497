import 'reflect-metadata';
import {Type} from 'class-transformer';
import {TableHtmlElementAttributes} from '@/app/entities/table/table-html-element-attributes.entity';
import {Entity} from '@/app/core/entity/main.entity';
import {TableAction} from '@/app/entities/table/table-action.entity';
import {TableFilter} from '@/app/entities/table/table-filter.entity';

export class TableHeader extends Entity {
  title: string;
  slug: string;
  description: string;
  columnType: TableColumnType = TableColumnType.text;
  column: {};

  @Type(() => TableAction)
  action: TableAction | null = null;

  @Type(() => TableFilter)
  filter: TableFilter | null = null;

  sorting: boolean = false;

  @Type(() => TableHtmlElementAttributes)
  htmlElementAttributes: TableHtmlElementAttributes;

  @Type(() => TableSelectionConfig)
  selectionConfig: TableSelectionConfig;

  @Type(() => TableHeader)
  children: TableHeader[];

  childrenSlugs: string[];
  parentSlug: string;
}

export class TableHeaderSorting extends Entity {
  slug: string;
  column: any;
}

export class TableSelectionConfig extends Entity {
  isSelectedByDefault: boolean = false;
  isDisabled: boolean = false;
  title: string;
  description: string;
}

export enum TableColumnType {
  date = 'date',
  dateRange = 'dateRange',
  dateTime = 'dateTime',
  finances = 'finances',
  financesWithColor = 'financesWithColor',
  number = 'number',
  text = 'text',

  /*
  * For progress
  *
  * use TableCalculation
  * */
  progress = 'progress',
  html = 'html',

  /*
  * For callback
  * {
  *   text: string;
  *   onClick: () => void;
  * }
  * */

  callback = 'callback',

  /*
* For price
* use NodeSettings
* */

  price = 'price',

  /*
  * For action
  *
  * use TableAction
  * */
  action = 'action',

  /*
  * For deepData
  *
  * use slug like ("['employee.firstName', 'employee.lastName']" as JSON string) to get data in object
  * */
  deepData = 'deepData',
}
