import {Component, EventEmitter, Output} from '@angular/core';
import {TuiButton} from '@taiga-ui/core';
import {SortDirection} from '@/app/entities/api/common/sort-modifire.entity';

@Component({
  selector: 'app-table-sort',
  standalone: true,
  imports: [
    TuiButton
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss'
})
export class TableSortComponent {
  @Output() onChange = new EventEmitter<SortDirection | null>();
  options = new Map<number, SortDirection | null>([
    [0, null],
    [1, SortDirection.ASC],
    [2, SortDirection.DESC],
  ]);

  currentId = 0;

  changeSort() {
    this.currentId = this.currentId === 2 ? 0 : this.currentId + 1;

    this.onChange.emit(this.options.get(this.currentId));
  }

  get currentIcon(): string {
    switch (this.currentId) {
      case 1:
        return '@tui.arrow-up-narrow-wide';
      case 2:
        return '@tui.arrow-down-wide-narrow';
      default:
        return '@tui.arrow-down-up';
    }
  }
}
