<div [class.pb-3]="control['field']().currentElementConfiguration.type !== 'hidden'">
  @if (control['field']().currentElementConfiguration.type === 'text' || control['field']().currentElementConfiguration.type === 'number') {
    @if (['text', 'link', 'email', 'slug'].includes(control['field']().currentElementConfiguration.configuration.type)) {
      <app-input
          type="text"
          [htmlType]="control['field']().currentElementConfiguration.configuration.htmlType ?? 'text'"
          [parentSlug]="controlSlug"
          [label]="control['field']().currentElementConfiguration.title"
          [formControl]='control'
          [minLength]="control['field']().currentElementConfiguration.configuration?.minLength ?? 0"
          [maxLength]="control['field']().currentElementConfiguration.configuration?.maxLength ?? 1000"
          [placeholder]="control['field']().currentElementConfiguration.configuration.placeholder ?? ''"
          [readonly]="control['field']().currentElementConfiguration.configuration?.readonly ?? loader"
          [clearable]="control['field']().currentElementConfiguration.configuration.clearable"
          [inputStyle]="computedInputStyle()"
      ></app-input>
    }

    @if (['number', 'price'].includes(control['field']().currentElementConfiguration.configuration.type)) {
      @if (!control['field']().currentElementConfiguration.configuration.between) {
        <app-input
            type="number"
            [parentSlug]="controlSlug"
            [label]="control['field']().currentElementConfiguration.title"
            [formControl]='control'
            [minValue]="control['field']().currentElementConfiguration.configuration?.minValue"
            [maxValue]="control['field']().currentElementConfiguration.configuration?.maxValue"
            [placeholder]="control['field']().currentElementConfiguration.placeholder ?? ''"
            [prefix]="control['field']().currentElementConfiguration.configuration.prefix ?? ''"
            [readonly]="loader"
            [inputStyle]="computedInputStyle()"
        ></app-input>
      } @else {
        <app-input
            type="number-range"
            [parentSlug]="controlSlug"
            [label]="control['field']().currentElementConfiguration.title"
            [formControl]='control'
            [minValue]="control['field']().currentElementConfiguration.configuration?.minValue"
            [maxValue]="control['field']().currentElementConfiguration.configuration?.maxValue"
            [placeholder]="control['field']().currentElementConfiguration.placeholder ?? ''"
            [readonly]="loader"
            [inputStyle]="computedInputStyle()"
        ></app-input>
      }
    }

    @if (['phone'].includes(control['field']().currentElementConfiguration.configuration.type)) {
      <app-input
          type="phone"
          [parentSlug]="controlSlug"
          [label]="control['field']().currentElementConfiguration.title"
          [formControl]='control'
          [mask]="control['field']().currentElementConfiguration.configuration.mask"
          [countryCode]="control['field']().currentElementConfiguration.configuration.countryCode"
          [readonly]="control['field']().currentElementConfiguration.configuration?.readonly ?? loader"
          [inputStyle]="computedInputStyle()"
      ></app-input>
    }

    @if (['pattern'].includes(control['field']().currentElementConfiguration.configuration.type)) {
      <app-input
          type="pattern"
          [htmlType]="'text'"
          [parentSlug]="controlSlug"
          [label]="control['field']().currentElementConfiguration.title"
          [formControl]='control'
          [characterPattern]="control['field']().currentElementConfiguration.configuration.pattern"
          [placeholder]="control['field']().currentElementConfiguration.placeholder ?? ''"
          [readonly]="control['field']().currentElementConfiguration.configuration?.readonly ?? loader"
          [inputStyle]="computedInputStyle()"
      ></app-input>
    }

    @if (control['field']().currentElementConfiguration.configuration.type === 'jql') {
      <app-jql-editor [formControl]='control' [label]="control['field']().currentElementConfiguration.title" />
    }
  }

  @if (control['field']().currentElementConfiguration.type === 'textarea') {
    <app-input
        type="textarea"
        [parentSlug]="controlSlug"
        [label]="control['field']().currentElementConfiguration.title"
        [formControl]='control'
        [placeholder]="control['field']().currentElementConfiguration.placeholder ?? ''"
        [readonly]="loader"
        [inputStyle]="computedInputStyle()"
    ></app-input>
  }

  @if (control['field']().currentElementConfiguration.type === 'toggle') {
    <app-input
        type="toggle"
        [parentSlug]="controlSlug"
        [label]="control['field']().currentElementConfiguration.title"
        [formControl]='control'
        [readonly]="loader"
        [inputStyle]="computedInputStyle()"
    ></app-input>
  }

  @if (control['field']().currentElementConfiguration.type === 'password') {
    <app-input
        type="password"
        [parentSlug]="controlSlug"
        [label]="control['field']().currentElementConfiguration.title"
        [formControl]='control'
        [placeholder]="control['field']().currentElementConfiguration.placeholder ?? ''"
        [readonly]="loader"
        [feedback]="control['field']().currentElementConfiguration.feedback ?? false"
        [inputStyle]="computedInputStyle()"
    ></app-input>
  }

  @if (control['field']().currentElementConfiguration.type === 'date') {
    @if(['datetime'].includes(control['field']().currentElementConfiguration.configuration.type)) {
      <app-datetime-picker
          [formControl]='control'
          [label]="control['field']().currentElementConfiguration.title"
          [minDate]="control['field']().currentElementConfiguration.configuration.minDate"
          [maxDate]="control['field']().currentElementConfiguration.configuration.maxDate"
          [showClear]="control['field']().currentElementConfiguration.configuration.clearable"
          [isRange]="!!control['field']().currentElementConfiguration.configuration.between"
          [format]="control['field']().currentElementConfiguration.configuration.format"
          [readonly]="loader"
      ></app-datetime-picker>
    } @else {
      <app-date-picker
          [formControl]='control'
          [label]="control['field']().currentElementConfiguration.title"
          [minDate]="control['field']().currentElementConfiguration.configuration.minDate"
          [maxDate]="control['field']().currentElementConfiguration.configuration.maxDate"
          [showClear]="control['field']().currentElementConfiguration.configuration.clearable"
          [isRange]="!!control['field']().currentElementConfiguration.configuration.between"
          [format]="control['field']().currentElementConfiguration.configuration.format"
          [readonly]="loader"
      ></app-date-picker>
    }

  }


  @if (control['field']().currentElementConfiguration.type === 'hidden') {
    <input
        [formControl]='control'
        type='hidden'
    >
  }

  @if (control['field']().currentElementConfiguration.type === 'segments') {
    <app-segment
        [formControl]='control'
        [options]="control['field']().currentElementConfiguration.configuration.dictionaryList"
        [readonly]="loader"
    ></app-segment>
  }

  @if (control['field']().currentElementConfiguration.type === 'select') {
    <app-form-select
        [formControl]='control'
        [elementConfig]="control['field']().currentElementConfiguration"
        [parentSlug]="controlSlug"
        [loader]="loader"
    ></app-form-select>
  }

  @if (control['field']().currentElementConfiguration.type === 'checkbox') {
    <app-checkbox-list
        [formControl]='control'
        [items]="control['field']().currentElementConfiguration.configuration.dataList"
        [label]="control['field']().currentElementConfiguration.title"
        [readonly]="loader"
    ></app-checkbox-list>
  }

  @if (control['field']().currentElementConfiguration.type === 'radio') {
    <app-radio
        [formControl]='control'
        [items]="control['field']().currentElementConfiguration.configuration.dataList"
        [direction]="control['field']().currentElementConfiguration.configuration.direction"
        [readonly]="loader"
    ></app-radio>
  }

  @if (computedMessages()) {
    @for (message of computedMessages(); track message.text) {
      <div [style.color]="getMessageColor(message.color)" class="message">{{ message.text }}</div>
    }
  }
  
<!--   <tui-error [error]='computedError()'></tui-error> -->

  @if (control['field']() && control['field']().currentElementConfiguration['description']) {
    <div class='mt-1 mb-1' [innerHTML]="control['field']().currentElementConfiguration['description']"></div>
  }
</div>
