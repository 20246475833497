import { Injectable } from '@angular/core';
import {ApiCoreService} from '@/app/services/core/api-core.service';
import {NodeConfiguration} from '@/app/entities/nodes/node-configuration.entity';
import {HttpClient} from '@angular/common/http';
import {
  ApiNodeConfigurationFilter,
  ApiNodeConfigurationModifier
} from '@/app/entities/api/types/api-node-configuration.type';

@Injectable({
  providedIn: 'root'
})
export class NodeConfigurationService extends ApiCoreService<NodeConfiguration>{

  constructor(
    http: HttpClient,
  ) {
    super(http);
    this.url = 'node/servcie';
  }

  override get(
    filter?: Partial<ApiNodeConfigurationFilter>,
    modifier?: Partial<ApiNodeConfigurationModifier>
  ) {
    return super.get(filter, modifier, undefined);
  }
}
