import {
  FormElementDateTypes,
  FormElementNumberTypes, FormElementRepeaterView, FormElementSegmentView,
  FormElementSize,
  FormElementTextTypes,
  FormElementType,
  FormElementValidationTypes
} from '../enums';
import 'reflect-metadata';
import {Transform, Type} from 'class-transformer';
import {Entity} from '@/app/core/entity/main.entity';
import {EntityArray} from '@/app/core/entity/entity-array';
import {DataList} from '@/app/entities/common/data-list.entity';

export class FormElementVisibility extends Entity {
  field?: string;
  value?: string[];
}

export class FormElementVisibilityArray extends EntityArray<FormElementVisibility> {

}


export class FormElementValidation extends Entity {
  type: FormElementValidationTypes;
  text: string;
  pattern?: string;
  relation: string;

  // only for Group
  relationFrom: string;

  color?: string = 'red';
  affectValidation?: boolean = true;

  constructor(options: Partial<FormElementValidation> = {}) {
    super();

    Object.assign(this, options);
  }
}

export class FormElementServerError extends Entity {
  text: string;
  color?: string = 'red';
  hideOnChange?: boolean = true;
  inited?: boolean = false;

  constructor(options: Partial<FormElementServerError> = {}) {
    super();

    Object.assign(this, options);
  }
}

export class FormConfiguration extends Entity {

}

export class FormElementConfiguration extends Entity {
  title?: string;
  placeholder?: string;
  type: FormElementType;
  description: string;
  elementSize?: FormElementSize = 'full';

  @Type(() => FormElementValidation)
  validation?: FormElementValidation[] = [];
}

export class FormElementConfigurationReplace extends Entity {
  applyIf: {field: string, value: string[]}[];
  isFullReplace: boolean = false;
  toReplace: {};
}

// toggle
export class FormElementToggle extends FormElementConfiguration {
  override type: FormElementType = 'toggle';

  @Type(() => FormElementToggleConfiguration)
  configuration?: FormElementToggleConfiguration = new FormElementToggleConfiguration();
}

export class FormElementToggleConfiguration extends FormConfiguration {
  label?: string;
}

// textarea
export class FormElementTextarea extends FormElementConfiguration {
  override type: FormElementType = 'textarea';

  @Type(() => FormElementCheckboxConfiguration)
  configuration?: FormElementTextareaConfiguration = new FormElementTextareaConfiguration();
}

export class FormElementTextareaConfiguration extends FormConfiguration {
  minLength?: number;
  maxLength?: number;
  clearable = true;
}

// text
export class FormElementText extends FormElementConfiguration {
  override type: FormElementType = 'text';

  @Type(() => FormElementTextConfiguration)
  configuration?: FormElementTextConfiguration = new FormElementTextConfiguration();
}
export class FormElementTextConfiguration extends FormConfiguration {
  type?: FormElementTextTypes = 'text';
  minLength?: number;
  maxLength?: number;
  clearable = true;
  readonly = false;
  pattern?: string;
  relatedField: string;

  // example (###) ###-##-##
  mask?: string;
  // example +7
  countryCode?: string;
}

// checkbox
export class FormElementCheckbox extends FormElementConfiguration {
  override type: FormElementType = 'checkbox';

  @Type(() => FormElementCheckboxConfiguration)
  configuration: FormElementCheckboxConfiguration = new FormElementCheckboxConfiguration();
}

export class FormElementCheckboxConfiguration extends FormConfiguration {
  dictionaryCode?: string;
  minSelected?: number;
  maxSelected?: number;

  @Type(() => DataList)
  dataList?: DataList[] = [];

}

// customCalendar
export class FormElementCustomCalendarRepeat extends FormElementConfiguration {
  override type: FormElementType = 'calendarRepeat';

  @Type(() => FormElementCustomCalendarRepeatConfiguration)
  configuration?: FormElementCustomCalendarRepeatConfiguration = new FormElementCustomCalendarRepeatConfiguration();
}

export class FormElementCustomCalendarRepeatConfiguration extends FormConfiguration {
  clearable = true;

  @Transform(({value}) => value ? new Date(value) : null)
  date: Date;
}

// number
export class FormElementNumber extends FormElementConfiguration {
  override type: FormElementType = 'number';

  @Type(() => FormElementNumberConfiguration)
  configuration?: FormElementNumberConfiguration = new FormElementNumberConfiguration();
}

export class FormElementNumberConfiguration extends FormConfiguration {
  type?: FormElementNumberTypes = 'number';
  mask?: string;
  clearable = true;
  minLength?: number;
  maxLength?: number;
  minValue?: number;
  maxValue?: number;
  between: boolean = false;

  // Only is between = false
  prefix?: string
}


// radio
export class FormElementRadio extends FormElementConfiguration {
  override type: FormElementType = 'radio';

  @Type(() => FormElementRadioConfiguration)
  configuration?: FormElementRadioConfiguration = new FormElementRadioConfiguration();
}
export class FormElementRadioConfiguration extends FormConfiguration {
  dictionaryCode?: string;

  @Type(() => DataList)
  dataList?: DataList[] = [];
  direction: 'column' | 'row' = 'row';
}

// select
export class FormElementSelect extends FormElementConfiguration {
  override type: FormElementType = 'select';

  @Type(() => FormElementSelectConfiguration)
  configuration?: FormElementSelectConfiguration = new FormElementSelectConfiguration();
}

export class FormElementSelectConfiguration extends FormConfiguration {
  @Type(() => DataList)
  dataList: DataList[] = [];
  multiple: boolean = false;
  ownVariants: boolean = false;
  search: boolean = true;
  minSelected: number | null = null;
  maxSelected: number | null = null;
  searchPlaceholder: string = 'Поиск...';
  notFoundText: string = 'Not found';
  clearable: boolean = true;
  urlToSearch: string | null = null;
  additionalRequest: object | null = null;
}

// hidden
export class FormElementHidden extends FormElementConfiguration {
  override type: FormElementType = 'hidden';

  @Type(() => FormElementHiddenConfiguration)
  configuration?: FormElementHiddenConfiguration = new FormElementHiddenConfiguration();
}

export class FormElementHiddenConfiguration extends FormConfiguration {

}

// segment
export class FormElementSegment extends FormElementConfiguration {
  override type: FormElementType = 'segments';

  @Type(() => FormElementSegmentConfiguration)
  configuration?: FormElementSegmentConfiguration = new FormElementSegmentConfiguration();
}

export class FormElementSegmentConfiguration extends FormConfiguration {
  dictionaryCode?: string;
  view?: FormElementSegmentView = 'secondary';

  @Type(() => DataList)
  dictionaryList?: DataList[] = [];

}

// group
export class FormElementGroup extends FormElementConfiguration {
  override type: FormElementType = 'group';

  @Type(() => FormElementGroupConfiguration)
  configuration?: FormElementGroupConfiguration = new FormElementGroupConfiguration();
}

export class FormElementRepeater extends FormElementConfiguration {
  override type: FormElementType = 'repeater';

  @Type(() => FormElementRepeaterConfiguration)
  configuration?: FormElementRepeaterConfiguration = new FormElementRepeaterConfiguration();
}

export class FormElementGroupConfiguration extends FormConfiguration {
  @Type(() => FormElement)
  fields: FormElement[] = [];
}

export class FormElementRepeaterConfiguration extends FormConfiguration {
  incrementTitle?: string;
  addButtonTitle?: string;
  view?: FormElementRepeaterView = 'full';

  fields: FormElement[] = [];
}

// date
export class FormElementData extends FormElementConfiguration {
  override type: FormElementType = 'date';

  @Type(() => FormElementDateConfiguration)
  configuration?: FormElementDateConfiguration = new FormElementDateConfiguration();
}

export class FormElementDateConfiguration extends FormConfiguration {
  format?: string = 'dd.MM.yyyy';
  type?: FormElementDateTypes = 'date';
  @Type(() => Date)
  @Transform(({value}) => value ? new Date(value) : null)
  minDate?: Date;

  @Type(() => Date)
  @Transform(({value}) => value ? new Date(value) : null)
  maxDate?: Date;
  clearable = true;
  between: boolean = false;
}

// password
export class FormElementPassword extends FormElementConfiguration {
  override type: FormElementType = 'password'

  @Type(() => FormElementPasswordConfiguration)
  configuration: FormElementPasswordConfiguration = new FormElementPasswordConfiguration()
}

export class FormElementPasswordConfiguration extends FormConfiguration {
}

export abstract class FormElement extends Entity {
  slug: string;

  @Type(() => Entity, {
    discriminator: {
      property: 'type',
      subTypes: [
        {value: FormElementText, name: 'text'},
        {value: FormElementHidden, name: 'hidden'},
        {value: FormElementTextarea, name: 'textarea'},
        {value: FormElementSelect, name: 'select'},
        {value: FormElementCheckbox, name: 'checkbox'},
        {value: FormElementData, name: 'date'},
        {value: FormElementGroup, name: 'group'},
        {value: FormElementNumber, name: 'number'},
        {value: FormElementRepeater, name: 'repeater'},
        {value: FormElementSegment, name: 'segments'},
        {value: FormElementToggle, name: 'toggle'},
        {value: FormElementRadio, name: 'radio'},
        {value: FormElementCustomCalendarRepeat, name: 'calendarRepeat'},
        {value: FormElementPassword, name: 'password'}
      ],
    },
    keepDiscriminatorProperty: true
  })
  elementConfiguration: FormElements;

  @Type(() => FormElementConfigurationReplace)
  elementConfigurationReplace?: FormElementConfigurationReplace[] = [];
  currentElementConfiguration: any = null;

  @Type(() => FormElementVisibility)
  hideIf?: FormElementVisibility[];

  @Type(() => FormElementVisibility)
  showIf?: FormElementVisibility[];

  isShow: boolean = true;
}

export type FormElements =
  FormElementText
  | FormElementTextarea
  | FormElementSelect
  | FormElementCheckbox
  | FormElementData
  | FormElementGroup
  | FormElementNumber
  | FormElementRadio
  | FormElementRepeater
  | FormElementSegment
  | FormElementToggle;
