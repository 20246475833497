import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  OnDestroy,
  Output, ViewChild,
} from '@angular/core';

import ReactDOM from 'react-dom';
import {createElement} from 'react';
import {JqlEditor} from '@/app/components/react/jql-editor/JqlEditor';
import {AbstractNgModelComponent} from '@/app/core/abstract/ng-model.component';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {environment} from '@/environments/environment';

@Component({
  selector: 'app-jql-editor',
  standalone: true,
  imports: [],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => JqlEditorComponent),
      multi: true
    }
  ],
})
export class JqlEditorComponent extends AbstractNgModelComponent implements AfterViewInit, OnDestroy {
  @ViewChild('container') container: ElementRef;
  @Output() jqlUpdate = new EventEmitter<string>();
  jql: string = '';

  ngAfterViewInit() {
    if (environment.isForge) {
      const el = createElement(JqlEditor)
      el.props.jql = this.jql;
      el.props.onUpdate = (jql: string) => {
       this.jqlUpdate.emit(jql);
       if (this.onChangeModel) {
         this.onChangeModel(jql);
       }
      }
      ReactDOM.render(el, this.container.nativeElement);
    }
  }

  ngOnDestroy() {
   if (environment.isForge) {
     ReactDOM.unmountComponentAtNode(this.container.nativeElement);
   }
  }

  override writeValue(value: any) {
    this.jql = value ?? '';
  }
}
