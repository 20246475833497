import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ContentChild, ElementRef, EventEmitter, inject,
  Input,
  OnChanges, OnDestroy,
  OnInit, Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {TreeTable, TreeTableModule} from 'primeng/treetable';
import {TableColumnType, TableHeader} from '@/app/entities/table/table-header.entity';
import {TableUserConfigOrder} from '@/app/entities/table/table-user-config.entity';
import {Table} from '@/app/entities/table/table.entity';
import {CommonModule} from '@angular/common';
import {TuiButton, TuiDataList, TuiDropdown, TuiDropdownHover, TuiIcon} from '@taiga-ui/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {DataList} from '@/app/entities/common/data-list.entity';
import {TreeModule} from 'primeng/tree';
import {TuiDataListDropdownManager} from '@taiga-ui/kit';
import {TableData} from '@/app/entities/table/table-data.entity';
import {CurrencyComponent} from '@/app/components/utils/currency/currency.component';
import {TableActionComponent} from '@/app/components/table-components/table-action/item.component';
import {tableTotalExpandedLength, tableTotalLength} from '@/app/utils/table/totalLength';
import {CalculationProgressComponent} from '@/app/components/calculation-progress/item.component';
import {TableAction} from '@/app/entities/table/table-action.entity';
import {TableFilterComponent} from '@/app/components/table-components/table-filter/item.component';
import {
  ITableControlButton,
  TableControlsComponent
} from '@/app/components/table-components/table-controls/item.component';
import {ApiPagination, ApiResultPagination} from '@/app/entities/api/api-pagination.entity';
import {TablePaginationComponent} from '@/app/components/table-components/table-pagination/item.component';
import {fromEvent, Subscription} from 'rxjs';
import {TableFooter} from '@/app/entities/table/table-footer.entity';
import {TableSortComponent} from '@/app/components/table-components/table-sort/item.component';
import {SortDirection, SortModifire} from '@/app/entities/api/common/sort-modifire.entity';


export interface TableRow {
  name: string;
  id: number;
  children: TableRow[];
}

interface ITableHeader {
  title: string;
  type: TableColumnType;
  action: TableAction | null;
}

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [
    TreeTableModule,
    CommonModule,
    TuiDropdownHover,
    TuiIcon,
    TuiDropdown,
    TuiButton,
    TreeModule,
    ReactiveFormsModule,
    TuiDataList,
    TuiDataListDropdownManager,
    CurrencyComponent,
    TableActionComponent,
    CalculationProgressComponent,
    TableFilterComponent,
    TableControlsComponent,
    TablePaginationComponent,
    TableSortComponent
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss'
})
export class TableComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  cdr = inject(ChangeDetectorRef);
  @ViewChild('tableMarker') tableMarker!: ElementRef;
  @ViewChild(TreeTable) treeTable!: TreeTable;
  @Input() isBlur: boolean = false;
  @Input() tableConfig!: Table;
  @Input() tableRows: TableData[] = [];
  @Input() isVirtual: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() isFullPage: boolean = true;
  @Input() hasPagination: boolean = true;

  // control
  @Input() controlButton: ITableControlButton[] = [
    {
      button:  {
        icon: '@tui.refresh-cw',
        appearance: 'outline',
        onClick: () => this.onRefresh.emit()
      }
    }
  ];
  @Input() controlIsHideSearch: boolean = false;
  @Input() isHideControl: boolean = false;
  @Output() onRefresh = new EventEmitter<void>();
  @Output() onSearch = new EventEmitter<string | null>();

  // pagination
  @Input() pagination: Partial<ApiResultPagination>;
  @Output() changePage = new EventEmitter<ApiPagination>();
  @Output() changePageSize = new EventEmitter<number>();

  // sorting
  @Output() onChangeSort = new EventEmitter<SortModifire | null>();

  
  colTypes = TableColumnType;

  @ContentChild('tbody') tbody: any;

  private _tableHeaderCopy: TableHeader[] = [];
  private _tableFooterCopy: TableFooter[] = [];
  private _tableHeader: TableHeader[][] = [];
  private _profileConfig: TableUserConfigOrder[][] = [];
  private _tableColumns: ITableHeader[] = [];

  private _maxHeight: string = '60dvh';

  maxChildDeep = 0;
  filterOpen = new Map<string, boolean>();
  filterControl = new FormControl();

  actionOpen = new Map<string, boolean>();
  totalLength: number = 0;
  totalExpandedLength: number = 0;

  sub: Subscription;
  scrollStartPosition = {
    top: 0,
    left: 0,
  };
  scrollElBound: DOMRect;
  isShadow = {
    top: false,
    bottom: false,
    left: false,
    right: false
  }

  constructor() {}

  ngOnInit() {
    this._tableHeaderCopy = [...this.tableConfig.header];
    if (this.tableConfig.userConfiguration && this.tableConfig.userConfiguration.profiles?.length) {
      this.userConfigBuilder(this.tableConfig.userConfiguration.profiles[0].headerColumns);
      this.headerReorder();
    }

    this.headerBuilder(this._tableHeaderCopy);
    this.setHtmlAttributes();
  }

  ngOnChanges(changes: SimpleChanges) {
    const {tableRows} = changes;
    if (tableRows) {
      this.maxChildDeep = this.countDeep(this.tableRows);
      this.totalLength = tableTotalLength(this.tableRows);
      this.totalExpandedLength = tableTotalExpandedLength(this.tableRows as any);
    }
  }

  ngAfterViewInit() {
    if (this.tableMarker) {
      setTimeout(() => {
        const el = this.tableMarker.nativeElement as HTMLElement;
        const addH = this.hasPagination ? 135 : 0;
        this._maxHeight = `${(window.innerHeight - el.getBoundingClientRect().top - window.scrollY) - addH}px`;
        this.cdr.detectChanges();
      })
    }

    if (this.treeTable) {
      const el = this.treeTable.el.nativeElement as HTMLElement;
      const tableScroll = el.querySelector('.p-treetable-scrollable-body');
      const table = tableScroll?.querySelector('table');
      if (tableScroll && table) {
        setTimeout(() => {
          const tableBound = table.getBoundingClientRect();
          this.scrollElBound = el.getBoundingClientRect();
          this.scrollStartPosition.top = tableBound.top;
          this.scrollStartPosition.left = tableBound.left;

          this._tableRightAndBottomShadowCalc(tableBound);
        });
        this.sub = fromEvent(tableScroll, 'scroll').subscribe(e => {
          const boundTable = table.getBoundingClientRect();
          if (boundTable.top < this.scrollStartPosition.top) {
            this.isShadow.top = true;
          } else {
            this.isShadow.top = false;
          }

          if (boundTable.left < this.scrollStartPosition.left) {
            this.isShadow.left = true;
          } else {
            this.isShadow.left = false;
          }

          this._tableRightAndBottomShadowCalc(boundTable);
        })
      }
    }
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  private _tableRightAndBottomShadowCalc(boundTable: DOMRect) {
    if (Math.abs(boundTable.top) < boundTable.height - this.scrollElBound.bottom) {
      this.isShadow.bottom = true;
    } else {
      this.isShadow.bottom = false;
    }

    if (this.scrollElBound.right < boundTable.right) {
      this.isShadow.right = true;
    } else {
      this.isShadow.right = false;
    }
  }

  userConfigBuilder(configs: TableUserConfigOrder[], idx: number = 0, parentSlug: string = '') :void {
    configs.forEach(config => {
      if (this._profileConfig[idx]) {
        this._profileConfig[idx] = [...this._profileConfig[idx], {...config, parentSlug, ...(config.children && config.children.length) && {childrenSlugs: this.getChildrenSlugs(config.children)}}];
      } else {
        this._profileConfig.push([{...config, parentSlug, ...(config.children && config.children.length) && {childrenSlugs: this.getChildrenSlugs(config.children)}}]);
      }

      if (config.children && config.children.length) {
        this.userConfigBuilder(config.children, idx+1, config.slug);
      }
    })
  }

  headerBuilder(headers: TableHeader[], idx: number = 0, parentSlug: string = '') :void {
    headers.forEach(header => {
      const nCfg = this._profileConfig[idx]?.find(cfg => cfg.slug === header.slug);
      if (header.selectionConfig && !header.selectionConfig.isSelectedByDefault) {
        if (nCfg && !nCfg.isActive) {
          return;
        }
      } else {
        if (nCfg && !nCfg.isActive) {
          return;
        }
      }

      if (this._tableHeader[idx]) {
        this._tableHeader[idx] = [...this._tableHeader[idx], {...header, parentSlug, ...(header.children && header.children.length) && {childrenSlugs: this.getChildrenSlugs(header.children)}}];
      } else {
        this._tableHeader.push([{...header, parentSlug, ...(header.children && header.children.length) && {childrenSlugs: this.getChildrenSlugs(header.children)}}]);
      }

      if (header.children && header.children.length) {
        this.headerBuilder(header.children, idx+1, header.slug);
      } else {
        this._tableColumns.push({
          title: header.slug,
          type: header.columnType,
          action: header.action
        });
      }
    })
  }

  footerBuilder() {
    this._tableFooterCopy.forEach(footer => {})
  }

  getChildrenSlugs(children: TableHeader[] | TableUserConfigOrder[]): string[] {
    return children.map(item => item.slug);
  }

  headerReorder(): void {
    this._tableHeader.forEach((headers, idx) => {
      headers.sort()
    })
  }

  getRowKeys(row: TableRow): string[] {
    // console.log({row})
    return Object.keys(row).filter(key => key !== 'children');
  }

  selectTr(row: TableRow) {
    // console.log({row})
  }

  setHtmlAttributes() {
    this._tableHeader.forEach((header, idx) => {
      const groups = new Map<string, number>();
      header.forEach(nHeader => {
        if (!nHeader.childrenSlugs?.length) {
          if (nHeader['htmlElementAttributes']) {
            nHeader['htmlElementAttributes'].rowspan = this._tableHeader.length - idx;
          } else {
            nHeader['htmlElementAttributes'] = {
              colspan: 1,
              rowspan: this._tableHeader.length - idx,
              class: []
            }
          }
        }

        if (nHeader.parentSlug) {
          if (groups.has(nHeader.parentSlug)) {
            groups.set(nHeader.parentSlug, groups.get(nHeader.parentSlug)! + (this.getHeaderBySlug(header, nHeader.parentSlug)?.htmlElementAttributes?.colspan || 1))
          } else {
            groups.set(nHeader.parentSlug, this.getHeaderBySlug(header, nHeader.parentSlug)?.htmlElementAttributes?.colspan || 1)
          }
        }
      });

      for (let key of groups.keys()) {
        if (this.getHeaderBySlug(this._tableHeader[idx - 1], key)['htmlElementAttributes']) {
          this.getHeaderBySlug(this._tableHeader[idx - 1], key)['htmlElementAttributes'].colspan = groups.get(key)!
        } else {
          this.getHeaderBySlug(this._tableHeader[idx - 1], key)['htmlElementAttributes'] = {
            colspan: groups.get(key)!,
            rowspan: 1,
            class: []
          }
        }
      }
    })
  }

  getHeaderBySlug(headers: TableHeader[], slug: string): TableHeader {
    return headers.find(h => h.slug === slug)!;
  }


  get tableHeader() {
    return this._tableHeader
  }

  get tableColumns(): ITableHeader[] {
    return this._tableColumns;
  }

  countDeep(rows: any[]): number {
    let maxDepth = 1;

    rows.forEach((node) => {
      if (node.children && node.children.length > 0) {
        const depth = this.countDeep(node.children) + 1;
        maxDepth = Math.max(maxDepth, depth);
      }
    });

    return maxDepth;
  }

  onOpenFilter(th: TableHeader) {
    this.filterOpen.set(th.slug, true);
  }

  actionClick(close?: () => void) {
    if (close) {
      close();
    }
  }

  onClickCallback(value: {
    onClick?: () => void
  }){
    if (value.onClick) {
      value.onClick();
    }
  }

  recalcExpanded() {
    this.totalExpandedLength = tableTotalExpandedLength(this.tableRows as any);
  }

  get tableHeight() {
    return this.totalExpandedLength < 10
      ? (60 * (this.totalExpandedLength > 0 ? this.totalExpandedLength : 1) + 14) + 'px'
      : this.isFullPage
        ? this._maxHeight
        : '60dvh';
  }

  onSort(slug: string, direction: SortDirection | null) {
    if (!direction) {
      this.onChangeSort.emit(null);
    } else {
      this.onChangeSort.emit({
        sortBy: slug,
        sortDirection: direction
      })
    }
  }

  deepDataParse(slug: string, data: any): string {
    const paths = JSON.parse(slug);
    const values = paths.map((path: string) => {
      const keys = path.split('.');
      let value = data;

      for (const key of keys) {
        if (value === null || value === undefined || !(key in value)) {
          return '';
        }
        value = value[key];
      }

      return value;
    });
    return values.join(' ').trim();
  }
}
