import {Component} from '@angular/core';

@Component({
  selector: 'app-project-general-page',
  standalone: true,
  imports: [
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
})
export class ProjectGeneralPageComponent {
}
