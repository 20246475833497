import {Component, inject, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NodeService} from '@/app/services/api/node.service';
import {plainToInstance} from 'class-transformer';
import {Table} from '@/app/entities/table/table.entity';
import {TableColumnType} from '@/app/entities/table/table-header.entity';
import {TableData} from '@/app/entities/table/table-data.entity';
import {ITableControlButton} from '@/app/components/table-components/table-controls/item.component';
import {ApiNodeModifier} from '@/app/entities/api/types/api-node.type';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {transformTreeNodeToTableCalculation} from '@/app/utils/table/transformTreeNodeToTableCalculation';
import {DateRange} from '@/app/entities/common/date-range.entity';
import {TableSkeletonComponent} from '@/app/skeleton/table-skeleton/table-skeleton.component';
import {TableComponent} from '@/app/components/table-components/table/item.component';
import {TableDateFilterComponent} from '@/app/components/table-components/table-date-filter/item.component';
import {isTreeNode} from '@/app/entities/guards/isTreeNode.guard';
import {TableAction} from '@/app/entities/table/table-action.entity';
import {ModalService} from '@/app/modals/service/modal.service';
import {Node} from '@/app/entities/nodes/node.entity';
import {prepareTableNodeDataToUpdate} from '@/app/utils/table/prepareTableNodeDataToUpdate';
import {CommonModalComponent} from '@/app/modals/components/common/item';
import {IButtonModalData} from '@/app/modals/type/modal';
import {Form} from '@/app/entities/form/form.entity';
import {projectBillingFormConfig} from '@/app/modals/form-config/project-billing.cfg';
import {ProjectService} from '@/app/services/page-utils/project.service';
import {openIssueModal} from '@/app/pages/projects/pages/helper/openIssueModal.helper';

@Component({
  selector: 'app-project-issues-page',
  standalone: true,
  imports: [
    TableSkeletonComponent,
    TableComponent,
    TableDateFilterComponent
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
})
export class ProjectIssuesPageComponent {
  @ViewChild('tableDateFilter', {read: TemplateRef}) tableDateFilter: TemplateRef<any>;

  aRoute = inject(ActivatedRoute);
  nodeService = inject(NodeService);
  modalService = inject(ModalService);
  projectService = inject(ProjectService);

  projectId: string;
  tableLoading = true;
  firstLoading = true;
  isRefresh  = false;

  tableCfg = plainToInstance(Table, {
    "header": [
      {
        "title": "Project/Issue",
        "slug": "code",
      },
      {
        "title": "Title",
        "slug": "title",
        action: <TableAction>{
          buttons: [
            {
              label: 'Integration',
              onClick: (data: Node) => {
                if (data.id) {
                  openIssueModal(this.modalService, this.nodeService, this.projectService, undefined, data.id);
                }
              }
            },
            {
              label: 'Delete',
              onClick: (data: Node) => {
                this.modalService.openConfirm({
                  title: 'Delete Task?',
                  text: 'Are you sure you want to delete the task?',
                  confirmLabel: 'Delete',
                  cancelLabel: 'Cancel',
                  onConfirm: () => {
                    this.nodeService.delete([
                      data.id
                    ]).subscribe(() => {
                      this.loadNodes();
                    })
                  }
                })
              }
            }
          ]
        }
      },
      {
        "title": "Type",
        "slug": "type",
      },
      {
        "title": "Price",
        "slug": "price",
        "columnType": TableColumnType.price,
        action: <TableAction>{
          buttons: [
            {
              label: 'Edit',
              onClick: (data: Node) => {
                const form = plainToInstance(Form, {
                  fields: [
                    ...projectBillingFormConfig()
                  ],
                  value: data.price?.isSelf ? data.price.data : null,
                })

                this.modalService.open({
                  dynamicComponent: CommonModalComponent,
                  size: 'large',
                  data: {
                    title: 'Edit price',
                    form,
                    isLoading: this.projectService.loading,
                    buttons: [
                      {
                        settings: {
                          title: 'Cancel',
                          appearance: 'flat',
                        },
                        isClose: true,
                      },
                      {
                        settings: {
                          title: 'Save',
                          appearance: 'primary',
                        },
                        isClose: true,
                      }
                    ],
                    callbackBeforeClose: (modal: CommonModalComponent, btn: IButtonModalData<CommonModalComponent>) => {
                      if (btn.settings.title === 'Cancel') {
                        return true;
                      }

                      modal.makeAllFormTouched = true;
                      modal.formElement.getValueWithValidData();

                      setTimeout(() => {
                        if (!modal.formElement.formGroup.invalid) {
                          const {billingModel, hourRate, price} = modal.form.value;

                          this.projectService.loading.set(true);
                          this.nodeService.update([
                            {
                              ...prepareTableNodeDataToUpdate(data),
                              settingsSelf: {
                                billingModel,
                                price: billingModel === 'fix' ? price : null,
                                hourRate: billingModel === 'time_and_material' ? hourRate : null,
                                estimation: null,
                                fromNodeId: null
                              }
                            }
                          ]).subscribe({
                            next: res => {
                              if (res.isSuccess) {
                                this.loadNodes();
                                modal.close();
                              }
                            },
                            error: err => {
                              this.projectService.loading.set(false);
                            }
                          })
                        }
                      })

                      return false;
                    },
                  }
                });
              }
            },
            {
              label: 'Delete',
              hideIfParent: true,
              onClick: (data: Node) => {
                this.modalService.openConfirm({
                  title: 'Delete Price?',
                  text: 'Are you sure you want to delete the price?',
                  confirmLabel: 'Delete',
                  cancelLabel: 'Cancel',
                  onConfirm: () => {
                    this.nodeService.update([
                      {
                        ...prepareTableNodeDataToUpdate(data),
                        settingsSelf: null
                      }
                    ]).subscribe(() => {
                      this.loadNodes();
                    })
                  }
                })
              }
            }
          ]
        }
      },
      {
        "title": "Assignee",
        "slug": JSON.stringify(['employee.firstName', 'employee.lastName']),
        "columnType": TableColumnType.deepData
      },
      {
        "title": "Time spent",
        "slug": "timeSpent",
        "columnType": TableColumnType.progress
      },
      {
        "title": "Profit",
        "slug": "profit",
        "columnType": TableColumnType.progress
      },
    ],
  });
  tableData: TableData[] = [];
  tableButtons: ITableControlButton[] = [];

  dataModifire: Partial<ApiNodeModifier> = {
    treeNode: true,
  }

  constructor() {
    this.aRoute.parent?.params
      .pipe(
        takeUntilDestroyed()
      ).subscribe(params => {
      if (params['projectId']) {
        this.projectId = params['projectId'];
      }

      this.loadNodes();
    });

    this.projectService.updateNode$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.loadNodes();
    })
  }

  ngAfterViewInit() {
    this.tableButtons  = [
      {
        template: this.tableDateFilter
      },
      {
        button:  {
          icon: '@tui.refresh-cw',
          appearance: 'outline',
          onClick: () => {
            this.isRefresh = true;
            this.loadNodes();
          }
        },
      }
    ];
  }

  loadNodes() {
    this.tableLoading = true;

    this.nodeService.get({
        id: [this.projectId],
      },
      this.dataModifire,
    ).subscribe(res => {
      if (res.isSuccess) {
        this.tableData = res.result.items?.length ? transformTreeNodeToTableCalculation(
          isTreeNode(res.result.items[0]) ? res.result.items[0].children : res.result.items as any,
          true,
        ) : [];
      }

      this.tableLoading = false;
      this.firstLoading = false;
      this.isRefresh = false;
      this.projectService.loading.set(false);
    })
  }

  onDateChange(date: DateRange | null) {
    delete this.dataModifire['date'];
    this.dataModifire = {
      ...this.dataModifire,
      ...date && {date}
    }

    this.loadNodes();
  }
}
