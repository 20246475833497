import {Injectable, signal} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  updateNode$ = new Subject<void>();
  updateProject$ = new Subject<void>();

  loading = signal(false);
}
