import {TreeNode} from '@/app/entities/common/tree-node.entity';
import {Node} from '@/app/entities/nodes/node.entity';
import {TableCalculation} from '@/app/entities/table/table-calculation.entity';
import {TablePrice} from '@/app/entities/table/table-price.entity';

export const transformTreeNodeToTableCalculation =
  (nodes: TreeNode<Node>[], withPrice: boolean = false, callbacks: {field: string, onClick: (item: Node) => void}[] = []): TreeNode<any>[] =>
  {
    return nodes.reduce((acc: TreeNode<any>[], item) => {
      const newItem: TreeNode<any> = {...item};
      const calculation = newItem.data.calculationsSelf || newItem.data.calculationParent;
      let changedFields = {};
      callbacks.forEach(call => {
        if (item.data[call.field]) {
          changedFields = {
            ...changedFields,
            [call.field]: {
              text: item.data[call.field],
              onClick: () => {
                call.onClick(item.data)
              }
            }
          }
        }
      });

      newItem.data = {
        ...newItem.data,
        ...changedFields,
        profit: <TableCalculation>{
          calculation,
          isSelf: newItem.data.calculationSelf ? true : false,
          isTime: false,
        },
        timeSpent: <TableCalculation>{
          calculation,
          isSelf: newItem.data.calculationSelf ? true : false,
          isTime: true,
        },
        ...withPrice && {
          price: <TablePrice>{
            data: newItem.data.settingsSelf ?? (newItem.data.settingsParent.length ? newItem.data.settingsParent[0] : null),
            isSelf: !!newItem.data.settingsSelf
          },
        }
      }

      if (newItem.children && newItem.children.length) {
        newItem.children = transformTreeNodeToTableCalculation(newItem.children, withPrice, callbacks);
      }

      acc.push(newItem);

      return acc;
    }, [])
  }
