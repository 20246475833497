import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  inject, Input,
  OnInit,
  signal,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {TableComponent} from '@/app/components/table-components/table/item.component';
import {
  ITableControlButton,
} from '@/app/components/table-components/table-controls/item.component';
import {Form} from '@/app/entities/form/form.entity';
import {plainToInstance} from 'class-transformer';
import {ModalService} from '@/app/modals/service/modal.service';
import {CommonModalComponent} from '@/app/modals/components/common/item';
import {NodeService} from '@/app/services/api/node.service';
import {DictionaryService} from '@/app/services/api/dictionary.service';
import {combineLatest, of} from 'rxjs';
import {DataList} from '@/app/entities/common/data-list.entity';
import {IButtonModalData} from '@/app/modals/type/modal';
import {TransactionService} from '@/app/services/api/transaction.service';
import {CustomDate} from '@/app/core/classes/custom-date';
import {Table} from '@/app/entities/table/table.entity';
import {TableColumnType} from '@/app/entities/table/table-header.entity';
import {TableData} from '@/app/entities/table/table-data.entity';
import {ApiPagination, ApiResultPagination} from '@/app/entities/api/api-pagination.entity';
import {TableDateFilterComponent} from '@/app/components/table-components/table-date-filter/item.component';
import {DateRange} from '@/app/entities/common/date-range.entity';
import {ApiTransactionFilter, ApiTransactionModifier} from '@/app/entities/api/types/api-transaction.type';
import {TableSkeletonComponent} from '@/app/skeleton/table-skeleton/table-skeleton.component';
import {SortModifire} from '@/app/entities/api/common/sort-modifire.entity';
import {transactionFormConfig} from '@/app/modals/form-config/transaction.cfg';

@Component({
  selector: 'app-transaction-reusable-page',
  standalone: true,
  imports: [
    TableComponent,
    TableDateFilterComponent,
    TableSkeletonComponent
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
})
export class TransactionReusablePageComponent implements OnInit, AfterViewInit {
  @ViewChild('dateFilter', {read: TemplateRef}) dateFilter: TemplateRef<any>;

  @Input() additionalFilter: Partial<ApiTransactionFilter>;

  modalService = inject(ModalService);
  nodeService = inject(NodeService);
  dictionaryService = inject(DictionaryService);
  transactionService = inject(TransactionService);
  cdr = inject(ChangeDetectorRef);

  modalFormCfg: Form;
  loading = signal(true);
  asyncForm = signal<Form | null>(null)
  tableLoading = false;
  firstLoading = true;

  tableCfg = plainToInstance(Table, {
    "header": [
      {
        "title": "Date",
        "slug": "addedAt",
        sorting: true,
      },
      {
        "title": "Title",
        "slug": "title",
      },
      {
        "title": "Description",
        "slug": "description",
      },
      {
        "title": "Category",
        "slug": "category",
      },
      {
        "title": "Project",
        "slug": "project",
      },
      {
        "title": "Issue",
        "slug": "issue",
      },
      {
        "title": "Amount",
        "slug": "amount",
        "columnType": TableColumnType.finances,
        sorting: true,
      },
    ],
  });

  tableData: TableData[] = [];

  tableButtons: ITableControlButton[] = [];
  isRefreshing = false;

  tablePagination: ApiResultPagination;
  tableFilter?: Partial<ApiTransactionFilter>;
  tableModifier?: Partial<ApiTransactionModifier>;

  ngOnInit() {
    this.getTransactions({
      perPage: 40,
      currentPage: 1
    });
  }

  ngAfterViewInit() {
    this.tableButtons  = [
      {
        button: {
          icon: '@tui.plus',
          appearance: 'outline',
          label: 'New transaction',
          disabled: this.loading,
          onClick: () => this.openNewTransactionModal()
        },
      },
      {
        template: this.dateFilter
      },
      {
        button:  {
          icon: '@tui.refresh-cw',
          appearance: 'outline',
          disabled: this.loading,
          onClick: () => {
            this.isRefreshing = true;
            this.getTransactions({
              currentPage: this.tablePagination.currentPage,
              perPage: this.tablePagination.perPage < 8 ? 8 : this.tablePagination.perPage
            });
          }
        },
      }
    ];
    this.cdr.detectChanges();
  }

  getTransactions(
    pagination?: ApiPagination
  ) {
    this.loading.set(true);
    this.tableLoading = true;
    pagination = pagination ? {
      ...pagination,
      perPage: pagination.perPage < 8 ? 8 : pagination.perPage
    } : undefined;
    this.transactionService.get(
      {
        ...this.tableFilter,
        ...this.additionalFilter && this.additionalFilter,
      },
      this.tableModifier,
      pagination
    ).subscribe({
      next: res => {
        if (res.isSuccess) {
          this.tableData = res.result.items.map(item => ({
            data: {
              addedAt: new CustomDate(item.addedAt).format(),
              title: item.title,
              description: item.description,
              category: item.category?.title ?? '',
              project: item.node?.title ?? '',
              issue: item.node?.code ?? '',
              amount: item.amount,
            }
          }));

          if (res.result.pagination) {
            this.tablePagination = res.result.pagination;
          }
        }
        this.tableLoading = false;
        this.firstLoading = false;
        this.isRefreshing = false;
        this.loading.set(false);
      },
      error: err => {
        this.tableLoading = false;
        this.firstLoading = false;
        this.isRefreshing = false;
        this.loading.set(false);
      }
    })
  }

  onChangePagination(data: ApiPagination) {
    this.tableLoading = true;
    this.getTransactions({
      currentPage: data.currentPage+1,
      perPage: data.perPage
    })
  }

  onChangePageSize(size: number) {
    this.tableLoading = true;
    this.getTransactions({
      currentPage: 1,
      perPage: size
    })
  }

  onDateChange(date: DateRange | null) {
    this.tableFilter = date ? {
      id: [],
      addedAt: date
    } : undefined;
    this.getTransactions({
      currentPage: 1,
      perPage: 40
    })
  }

  onSearch(value: string | null) {
    const search = value ?? '';
    this.tableModifier = {
      ...this.tableModifier && this.tableModifier,
      search
    };

    this.getTransactions({
      currentPage: 1,
      perPage: 40
    })
  }

  openNewTransactionModal() {
    const filterNodeId = this.additionalFilter?.nodeId ? +this.additionalFilter.nodeId[0] : null;
    this.loading.set(true);
    const nodeRequest = !filterNodeId ? this.nodeService.get({
      isProject: true
    }, {
      treeNode: true,
      addChildrens: true
    }) : of(null);
    combineLatest([
      nodeRequest,
      this.dictionaryService.get({
        id: [],
        type: ['transaction_category'],
      }, {
        isDataList: true
      })
    ]).subscribe(([nodeRes, dictionaryRes]) => {
      if (dictionaryRes.isSuccess) {
        this.asyncForm.set(plainToInstance(Form, {
          fields: [
            ...transactionFormConfig(
              dictionaryRes.result.items as DataList[],
              nodeRes ? nodeRes.result.items as DataList[] : undefined
            )
          ],
          value: {
            type: 'income'
          }
        }))
      }
      this.loading.set(false);
    });

    this.modalService.open({
      dynamicComponent: CommonModalComponent,
      size: 'large',
      data: {
        title: 'New transaction',
        asyncForm: this.asyncForm,
        isLoading: this.loading,
        buttons: [
          {
            settings: {
              title: 'Cancel',
              appearance: 'flat',
            },
            isClose: true,
          },
          {
            settings: {
              title: 'Save',
              appearance: 'primary',
            },
            isClose: true,
          }
        ],
        callbackBeforeClose: (modal: CommonModalComponent, btn: IButtonModalData<CommonModalComponent>) => {
          if (btn.settings.title === 'Cancel') {
            return true;
          }

          modal.makeAllFormTouched = true;
          modal.formElement.getValueWithValidData();

          if (modal.formElement.formGroup.invalid) {
            return false;
          }

          const {date, nodeId, ...value} = modal.form.value;

          this.loading.set(true);
          this.transactionService.update([{
            ...value,
            nodeId: filterNodeId ? +filterNodeId : nodeId,
            addedAt: (new CustomDate(date)).format('yyyy-MM-dd'),
          }]).subscribe({
            next: res => {
              if (res.isSuccess) {
                modal.close();
                this.getTransactions(this.tablePagination)
              } else {
                this.loading.set(false);
              }
            },
            error: err => {
              this.loading.set(false);
            }
          })

          return false;
        },
      }
    });
  }

  onChangeSort(sort: SortModifire | null) {
    this.tableModifier = {
      ...this.tableModifier && this.tableModifier,
      sort
    }

    this.getTransactions({
      currentPage: 1,
      perPage: this.tablePagination.perPage
    })
  }
}
