import {Component, inject, Input, OnInit} from '@angular/core';
import {plainToInstance} from 'class-transformer';
import {Table} from '@/app/entities/table/table.entity';
import {TableColumnType} from '@/app/entities/table/table-header.entity';
import {TableSkeletonComponent} from '@/app/skeleton/table-skeleton/table-skeleton.component';
import {TableComponent} from '@/app/components/table-components/table/item.component';
import {EmployeeService} from '@/app/services/api/employee.service';
import {ApiEmployeeFilter, ApiEmployeeModifier} from '@/app/entities/api/types/api-employee.type';
import {ApiPagination, ApiResultPagination} from '@/app/entities/api/api-pagination.entity';
import {TableData} from '@/app/entities/table/table-data.entity';
import {CustomDate} from '@/app/core/classes/custom-date';
import {Router} from '@angular/router';
import {TableFilter} from '@/app/entities/table/table-filter.entity';

@Component({
  selector: 'app-employees-reusable-page',
  standalone: true,
  imports: [
    TableSkeletonComponent,
    TableComponent,
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
})
export class EmployeesReusablePageComponent implements OnInit {
  @Input() additionalFilter: Partial<ApiEmployeeFilter>;

  employeeService = inject(EmployeeService);
  router = inject(Router);

  firstLoading = true;
  tableLoading = false;
  isRefreshing = false;

  tableCfg = plainToInstance(Table, {
    "header": [
      {
        "title": "Employee",
        "slug": "employee",
        "columnType": TableColumnType.callback,
      },
      {
        "title": "Active contract period",
        "slug": "contractPeriod",
      },
      {
        "title": "Contract type",
        "slug": "contractType",
        filter: <TableFilter>{
          isSingle: true,
          callback: (ids) => {
            if (this.dataFilter) {
              if (ids?.length) {
                this.dataFilter = {...this.dataFilter, contracts: {
                    paymentType: ids[0] as any
                }}
              } else {
                delete this.dataFilter.contracts;
              }
            } else {
              if (ids?.length) {
                this.dataFilter = {contracts: {
                    paymentType: ids[0] as any
                }}
              }
            }
            this.loadData({
              perPage: this.dataPagination.perPage!,
              currentPage: 1,
            });
          },
          options: [
            {
              id: 'fix',
              key: 'fix',
              label: 'Fix'
            },
            {
              id: 'rate',
              key: 'rate',
              label: 'Rate'
            }
          ]
        }
      },
      {
        "title": "Contract amount",
        "slug": "contractAmount",
        "columnType": TableColumnType.finances
      },
      {
        "title": "Time Spent",
        "slug": "timeSpent",
      },
      {
        "title": "Profit",
        "slug": "profit",
      },
    ],
  });
  tableData: TableData[] = [];

  dataFilter?: Partial<ApiEmployeeFilter>;
  dataModifier?: Partial<ApiEmployeeModifier>;
  dataPagination: Partial<ApiResultPagination> = {
    perPage: 40,
    currentPage: 1
  };

  ngOnInit() {
    this.loadData({
      perPage: this.dataPagination.perPage!,
      currentPage: this.dataPagination.currentPage!,
    });
  }


  onSearch(value: string | null) {
    this.dataModifier = {
      ...this.dataModifier && this.dataFilter,
      search: value ?? '',
    }

    this.loadData({
      perPage: 40,
      currentPage: 1,
    });
  }

  onChangePagination(data: ApiPagination) {
    this.tableLoading = true;
    this.loadData({
      currentPage: data.currentPage+1,
      perPage: data.perPage
    })
  }

  onChangePageSize(size: number) {
    this.tableLoading = true;
    this.loadData({
      currentPage: 1,
      perPage: size
    })
  }

  loadData(
    pagination?: ApiPagination
  ) {
    this.tableLoading = true;

    this.employeeService.get(
      {
        ...this.dataFilter,
        ...this.additionalFilter && this.additionalFilter
      },
      this.dataModifier,
      pagination
    ).subscribe(res => {
      if (res) {

        this.tableData = res.result.items.map((item) => {

          let contractPeriod = '-'

          if (item.activeContract?.startAt) {
            contractPeriod = new CustomDate(item.activeContract.startAt).format()
          }
          if (item.activeContract?.endAt) {
            contractPeriod += ` - ${new CustomDate(item.activeContract.endAt).format()}`
          }

          return {
            data: {
              employee: {
                text: `${item.firstName} ${item.lastName} ${item.middleName}`,
                onClick: () => {
                  this.router.navigate(['/employees', item.id, 'issues'])
                }
              },
              contractPeriod,
              contractType: item.activeContract ? item.activeContract.paymentType : '-',
              contractAmount: item.activeContract
                ? item.activeContract.paymentType === 'fix'
                  ? item.activeContract.salary
                  : item.activeContract.hourRate
                : '-'
              ,
              timeSpent: 0,
              profit: 0,
            }
          }
        })

        if (res.result.pagination) {
          this.dataPagination = {
            ...res.result.pagination,
            perPage: res.result.pagination.perPage < 8 ? 8 : res.result.pagination.perPage,
          };
        }
      }

      this.firstLoading = false;
      this.tableLoading = false;
      this.isRefreshing = false
    })
  }

  onRefresh() {
    this.isRefreshing = true;
    this.loadData({
      perPage: this.dataPagination.perPage!,
      currentPage: this.dataPagination.currentPage!,
    });
  }
}
