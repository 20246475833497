import React, {useEffect, useState } from 'react';
import {environment} from '@/environments/environment';

interface JQLEditorProps {
  jql: string;
  onUpdate: (jql: string) => void;
}

export const JqlEditor: React.FC<JQLEditorProps> = ({jql = '', onUpdate}) => {
  const [JQLEditorForge, setJQLEditorForge] = useState<any>(null);
  const isForge = environment.isForge;

  useEffect(() => {
    if (isForge) {
      import('@atlassianlabs/jql-editor-forge')
        .then((module) => {
          setJQLEditorForge(() => module.default);
        })
        .catch((error) => {
          console.error('Failed to load JQL Editor Forge:', error);
        });
    }
  }, [isForge]);


  if (!isForge) {
    return <div>Local environment: Forge-specific component is not loaded.</div>;
  }

  return JQLEditorForge
    ? <JQLEditorForge
      query={jql}
      locale={'en'}
      onUpdate={onUpdate}
    />
    : <div>Loading JQL Editor Forge...</div>;
};

