export const dictionaryFormConfig = (dictionaryTitle: string = 'Dictionary') => {
  return [
    {
      slug: 'title',
      elementConfiguration: {
        title: dictionaryTitle,
        type: 'text',
        configuration:  {
          type: 'text',
          clearable: true,
        },
        validation: [
          {
            type: 'required',
            text: 'Required field'
          }
        ],
      }
    },
  ]
}
