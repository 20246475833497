import {DataList} from '@/app/entities/common/data-list.entity';

export const projectBillingFormConfig = () => {
  return [
    {
      slug: 'billingModel',
      elementConfiguration: {
        title: 'Billing model',
        type: 'select',
        configuration:  {
          dataList: <DataList[]>[
            {
              id: 'fix',
              key: 'fix',
              label: 'Fix'
            },
            {
              id: 'time_and_material',
              key: 'time_and_material',
              label: 'Time and material'
            }
          ],
          search: false,
          clearable: true,
        },
        validation: [
          {
            type: 'required',
            text: 'Required field'
          }
        ],
      }
    },
    {
      slug: 'price',
      elementConfiguration: {
        title: 'Price',
        type: 'number',
        configuration:  {
          between: false,
          minValue: 0,
          prefix: '$ ',
          clearable: true,
        },
        validation: [
          {
            type: 'required',
            text: 'Required field'
          }
        ],
      },
      showIf: [
        {
          field: 'billingModel',
          value: ['fix']
        }
      ]
    },
    {
      slug: 'hourRate',
      elementConfiguration: {
        title: 'Hour Rate',
        type: 'number',
        configuration:  {
          between: false,
          minValue: 0,
          prefix: '$ ',
          clearable: true,
        },
        validation: [
          {
            type: 'required',
            text: 'Required field'
          }
        ],
      },
      showIf: [
        {
          field: 'billingModel',
          value: ['time_and_material']
        }
      ]
    },
  ]
}
