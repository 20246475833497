import {Component, CUSTOM_ELEMENTS_SCHEMA, inject, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router';
import {TuiTabs} from '@taiga-ui/kit';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {filter} from 'rxjs';
import {PageWrapperComponent} from '@/app/components/page/page-wrapper/item.component';
import {NodeService} from '@/app/services/api/node.service';
import {Node} from '@/app/entities/nodes/node.entity';
import {PageWrapperButton} from '@/app/types/page/page-wrapper-button.type';
import {ModalService} from '@/app/modals/service/modal.service';
import {DataList} from '@/app/entities/common/data-list.entity';
import {openIssueModal} from '@/app/pages/projects/pages/helper/openIssueModal.helper';
import {ProjectService} from '@/app/services/page-utils/project.service';
import {CommonModalComponent} from '@/app/modals/components/common/item';
import {IButtonModalData} from '@/app/modals/type/modal';
import {plainToInstance} from 'class-transformer';
import {Form} from '@/app/entities/form/form.entity';
import {NodeConfigurationService} from '@/app/services/api/node-configuration.service';

@Component({
  selector: 'app-project-wrap-page',
  standalone: true,
  imports: [
    PageWrapperComponent,
    RouterOutlet,
    TuiTabs,
    RouterLink,
    RouterLinkActive
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProjectWrapPageComponent implements OnInit {
  router = inject(Router);
  aRoute = inject(ActivatedRoute);
  nodeService = inject(NodeService);
  nodeConfigService = inject(NodeConfigurationService);
  modalService = inject(ModalService);
  projectService = inject(ProjectService);

  projectId: string;
  data: Node;
  isLoading = true;

  private defaultButtons: PageWrapperButton[] = [
    {
      title: 'Integrations',
      icon: '@tui.upload',
      loading: this.projectService.loading,
      callback: () => {
        this.modalService.open({
          dynamicComponent: CommonModalComponent,
          size: 'large',
          data: {
            title: 'Integrations',
            form: plainToInstance(Form, {
              fields: [
                {
                  slug: 'jqlString',
                  elementConfiguration: {
                    title: 'JQL (advanced)',
                    type: 'text',
                    configuration:  {
                      type: 'jql',
                    },
                    validation: [
                      {
                        type: 'required',
                        text: 'Required field'
                      }
                    ],
                  }
                },
                {
                  slug: 'isUpdate',
                  elementConfiguration: {
                    title: 'update existed',
                    type: 'toggle',
                  }
                },
                {
                  slug: 'isCreate',
                  elementConfiguration: {
                    title: 'create new',
                    type: 'toggle',
                  }
                },
                {
                  slug: 'isDelete',
                  elementConfiguration: {
                    title: 'delete',
                    type: 'toggle',
                  }
                },
              ]
            }),
            buttons: [
              {
                settings: {
                  title: 'Cancel',
                  appearance: 'flat',
                },
                isClose: true,
              },
              {
                settings: {
                  title: 'Save',
                  appearance: 'primary',
                },
                isClose: true,
              }
            ],
            callbackBeforeClose: (modal: CommonModalComponent, btn: IButtonModalData<CommonModalComponent>) => {
              if (btn.settings.title === 'Cancel') {
                return true;
              }
              this.projectService.loading.set(true);
              modal.makeAllFormTouched = true;
              modal.formElement.getValueWithValidData();

              setTimeout(() => {
                if (!modal.formElement.formGroup.invalid) {
                  const {jqlString, isCreate, isUpdate, isDelete} = modal.form.value;
                  this.nodeConfigService.update([
                    {
                      jqlString,
                      isCreate: !!isCreate,
                      isUpdate: !!isUpdate,
                      isDelete: !!isDelete
                    }
                  ]).subscribe({
                    next: res => {
                      if (res.isSuccess) {
                        modal.close();
                      }
                      this.projectService.loading.set(false);
                    },
                    error: () => {
                      this.projectService.loading.set(false)
                    }
                  });
                }
              })

              return false;
            },
          },
        })
      }
    },
    {
      title: 'Edit project',
      icon: '@tui.pencil',
      loading: this.projectService.loading,
      callback: () => {
        openIssueModal(
          this.modalService,
          this.nodeService,
          this.projectService,
          undefined,
          undefined,
          {
            code: this.data.code,
            title: this.data.title,
            description: this.data.description,
            billingModel: this.data.settingsSelf?.billingModel,
            price: this.data.settingsSelf?.price,
            hourRate: this.data.settingsSelf?.hourRate,
          },
          true,
          this.data
        )
      }
    },
    {
      title: 'Delete project',
      icon: '@tui.trash',
      loading: this.projectService.loading,
      callback: () => {
        console.log('Delete project')
      }
    },
  ]

  controlButtons: PageWrapperButton[] = [];

  constructor() {
    this.aRoute.params.pipe(
      takeUntilDestroyed(),
    ).subscribe(params => {
      if (params['projectId']) {
        this.projectId = params['projectId'];
        this.loadNode();
      }
    })

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntilDestroyed(),
    ).subscribe(() => {
      this.logActiveChildRoute(this.aRoute);
    });

    this.projectService.updateProject$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.loadNode()
    })
  }

  routes = [
    {
      label: 'General',
      url: 'general'
    },
    {
      label: 'Issues',
      url: 'issues'
    },
    {
      label: 'Employees',
      url: 'employees'
    },
    {
      label: 'Transactions',
      url: 'transactions'
    },
  ];

  ngOnInit() {
    this.logActiveChildRoute(this.aRoute);
  }

  loadNode() {
    this.nodeService.get({
      id: [this.projectId],
      isProject: true,
    }, {
      addChildrens: false,
    }).subscribe({
      next: res => {
        if (res?.isSuccess) {
          this.data = res.result.items[0] as Node;
        }
        this.isLoading = false;
      },
      error: err => {
        this.isLoading = false;
      }
    })
  }

  private logActiveChildRoute(route: ActivatedRoute) {
    let currentRoute = route;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }

    const path = currentRoute.snapshot.routeConfig?.path;

    if (path === 'issues') {
      this.controlButtons = [
        {
          title: 'Add issue',
          icon: '@tui.plus',
          loading: this.projectService.loading,
          callback: () => {
            this.projectService.loading.set(true);
            this.nodeService.get({
              id: [this.data.id.toString()],
              withExternalId: false
            }, {
              isDataList: true,
              addChildrens: true
            }).subscribe(res => {
              const dataList = res.result.items?.length
                ? res.result.items as DataList[]
                : undefined;

              this.projectService.loading.set(false);

              openIssueModal(this.modalService, this.nodeService, this.projectService, dataList);
            })
          }
        },
        ...this.defaultButtons
      ];
    } else {
      this.controlButtons = [...this.defaultButtons];
    }
  }
}
