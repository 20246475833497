import {Component, CUSTOM_ELEMENTS_SCHEMA, inject, signal} from '@angular/core';
import {PageWrapperComponent} from '@/app/components/page/page-wrapper/item.component';
import {NodeService} from '@/app/services/api/node.service';
import {TableSkeletonComponent} from '@/app/skeleton/table-skeleton/table-skeleton.component';
import {TableComponent} from '@/app/components/table-components/table/item.component';
import {plainToInstance} from 'class-transformer';
import {Table} from '@/app/entities/table/table.entity';
import {TableColumnType} from '@/app/entities/table/table-header.entity';
import {TableData} from '@/app/entities/table/table-data.entity';
import {transformTreeNodeToTableCalculation} from '@/app/utils/table/transformTreeNodeToTableCalculation';
import {TableAction} from '@/app/entities/table/table-action.entity';
import {Form} from '@/app/entities/form/form.entity';
import {CommonModalComponent} from '@/app/modals/components/common/item';
import {IButtonModalData} from '@/app/modals/type/modal';
import {ModalService} from '@/app/modals/service/modal.service';
import {DataList} from '@/app/entities/common/data-list.entity';
import {Router} from '@angular/router';
import {Node} from '@/app/entities/nodes/node.entity';
import {projectFormConfig} from '@/app/modals/form-config/project.cfg';

@Component({
  selector: 'app-projects-page',
  standalone: true,
  imports: [
    PageWrapperComponent,
    TableSkeletonComponent,
    TableComponent
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ProjectsPageComponent {
  nodeService = inject(NodeService);
  modalService = inject(ModalService);
  router = inject(Router);

  firstLoading = true;
  isRefresh = false;
  loading = signal(false);

  tableCfg = plainToInstance(Table, {
    "header": [
      {
        "title": "Code",
        "slug": "code",
        columnType: TableColumnType.callback,
        action: <TableAction>{
          buttons: [
            {
              label: 'Delete',
              onClick: (data: Node) => {
                if (data.id) {
                  this.loading.set(true);
                  this.modalService.openConfirm({
                    title: `Delete ${data.title}?`,
                    text: `Are you sure you want to delete the ${data.title}?`,
                    confirmLabel: 'Delete',
                    onConfirm: () => {
                      this.nodeService.delete([data.id]).subscribe(res => {
                        if (res.isSuccess) {
                          this.loadData();
                        } else {
                          this.loading.set(false);
                        }
                      });
                    }
                  })

                }
              }
            }
          ]
        }
      },
      {
        "title": "Title",
        "slug": "title",
      },
      {
        "title": "Price",
        "slug": "price",
        columnType: TableColumnType.price
      },
      {
        "title": "Time spent",
        "slug": "timeSpent",
        columnType: TableColumnType.progress
      },
      {
        "title": "Profit",
        "slug": "profit",
        columnType: TableColumnType.progress
      },
    ],
    footer: [
      {
        title: 'Total',
        slug: 'code',
        htmlElementAttributes: {
          colspan: 3
        }
      },
      {
        title: '190h',
        slug: 'timeSpent'
      },
      {
        title: '$456',
        slug: 'profit'
      },
    ]
  });
  tableData: TableData[] = [];

  tableButtons  = [
    {
      button: {
        icon: '@tui.plus',
        appearance: 'outline',
        label: 'New project',
        disabled: this.loading,
        onClick: () => this.openModal()
      },
    },
    {
      button:  {
        icon: '@tui.refresh-cw',
        appearance: 'outline',
        disabled: this.loading,
        onClick: () => {
          this.isRefresh = true;
          this.loadData()
        }
      },
    }
  ];

  modalFormCfg = plainToInstance(Form, {
    fields: [...projectFormConfig()],
  });

  constructor() {
    this.loadData();
  }

  loadData() {
    this.loading.set(true);
    this.nodeService.get({
      isProject: true,
    }, {
      treeNode: true,
      addChildrens: false
    }).subscribe({
      next: res => {
        if (res.isSuccess) {
          this.tableData = transformTreeNodeToTableCalculation(
            res.result.items as any,
            true,
            [
              {
                field: 'code',
                onClick: (item) => {
                  if (item?.id) {
                    this.router.navigateByUrl(`/projects/${item.id}/general`);
                  }
                }
              }
            ]
          );
        }

        this.loading.set(false);
        this.firstLoading = false;
        this.isRefresh = false;
      },
      error: err => {
        this.loading.set(false);
        this.firstLoading = false;
        this.isRefresh = false;
      }
    })
  }

  openModal() {
    this.modalService.open({
      dynamicComponent: CommonModalComponent,
      size: 'large',
      data: {
        title: 'New project',
        form: this.modalFormCfg,
        isLoading: this.loading,
        buttons: [
          {
            settings: {
              title: 'Cancel',
              appearance: 'flat',
            },
            isClose: true,
          },
          {
            settings: {
              title: 'Save',
              appearance: 'primary',
            },
            isClose: true,
          }
        ],
        callbackBeforeClose: (modal: CommonModalComponent, btn: IButtonModalData<CommonModalComponent>) => {
          if (btn.settings.title === 'Cancel') {
            return true;
          }

          modal.makeAllFormTouched = true;
          modal.formElement.getValueWithValidData();

          setTimeout(() => {
            if (!modal.formElement.formGroup.invalid) {
              const {code, title, description, price, billingModel, hourRate} = modal.form.value;
              this.loading.set(true);
              this.nodeService.update([{
                code,
                title,
                description,
                type: 'project',
                settingsSelf: {
                  billingModel,
                  price: billingModel === 'fix' ? price : null,
                  hourRate: billingModel === 'fix' ? null : hourRate,
                  fromNodeId: null,
                  estimation: null
                }
              }]).subscribe({
                next: res => {
                  if (res.isSuccess) {
                    this.loadData();
                    modal.close();
                  }
                },
                error: err => {
                  this.loading.set(false);
                }
              })
            }
          })
          return false;
        },
      }
    });
  }
}
