<button
    appearance="flat"
    iconStart="@tui.ellipsis-vertical"
    size="m"
    tuiIconButton
    type="button"
    [tuiAppearanceState]="open ? 'active' : null"
    [tuiDropdown]="dropdown"
    [tuiDropdownSided]="true"
    [(tuiDropdownOpen)]="open"
></button>
<ng-template
  #dropdown
  let-close
>
  <tui-data-list>
    <tui-opt-group>
      @for (btn of action.buttons; track $index) {
       @if (!(btn.hideIfParent && !data[slug]?.isSelf)) {
         <button
             tuiOption
             type="button"
             (click)="onClick(btn)"
         >
           {{ btn.label }}
         </button>
       }
      }

    </tui-opt-group>
  </tui-data-list>
</ng-template>
