import {AfterViewInit, Component, CUSTOM_ELEMENTS_SCHEMA, inject, signal, ViewChild} from '@angular/core';
import {CurrencyFormatPipe} from '@/app/pipes/currency-format.pipe';
import {TuiAlertService, TuiAppearanceOptions, TuiButton, TuiIcon} from '@taiga-ui/core';
import {ModalService} from '@/app/modals/service/modal.service';
import {FormElementComponent} from '@/app/components/form-components/form-constructor/form-element/item';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {plainToInstance} from 'class-transformer';
import {Form} from '@/app/entities/form/form.entity';
import {TuiComboBoxModule} from '@taiga-ui/legacy';
import {DataList} from '@/app/entities/common/data-list.entity';
import {HourDurationPipe} from '@/app/pipes/hour-duration.pipe';
import {CurrencyComponent} from '@/app/components/utils/currency/currency.component';
import {HourDurationComponent} from '@/app/components/utils/hour-duration/hour-duration.component';
import {TableSkeletonComponent} from '@/app/skeleton/table-skeleton/table-skeleton.component';
import {IAlert} from '@/app/alerts/alert/alert.interface';
import {PolymorpheusComponent} from '@taiga-ui/polymorpheus';
import {AlertComponent} from '@/app/alerts/alert/alert.component';
import {PageWrapperComponent} from '@/app/components/page/page-wrapper/item.component';
import {TableComponent} from '@/app/components/table-components/table/item.component';
import {TableControlsComponent} from '@/app/components/table-components/table-controls/item.component';
import {CalculationProgressComponent} from '@/app/components/calculation-progress/item.component';
import {NodeCalculation} from '@/app/entities/nodes/node-calculation.entity';
import {EmployeeService} from '@/app/services/api/employee.service';
import {Table} from '@/app/entities/table/table.entity';
import {CommonModalComponent} from '@/app/modals/components/common/item';
import {JqlEditorComponent} from '@/app/components/react/jql-editor/item.component';

@Component({
  selector: 'app-ui-kit',
  standalone: true,
  imports: [
    CurrencyFormatPipe,
    TuiButton,
    ReactiveFormsModule,
    TuiComboBoxModule,
    TuiIcon,
    FormElementComponent,
    HourDurationPipe,
    CurrencyComponent,
    HourDurationComponent,
    TableSkeletonComponent,
    PageWrapperComponent,
    TableComponent,
    TableControlsComponent,
    CalculationProgressComponent,
    JqlEditorComponent,
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UiKitComponent implements AfterViewInit {
  private readonly alerts = inject(TuiAlertService);
  private readonly modalService = inject(ModalService);
  private readonly employeeService = inject(EmployeeService);

  isLoadingModal = signal(false);
  isBlur = false;

  @ViewChild('formElement') formElement: FormElementComponent;
  formEl = new FormControl();

  progressData:NodeCalculation[] = [
    {
      month: '',
      expenses: {
        value: 140,
        percent: 14,
        type: 'finances'
      },
      organizationExpenses: {
        value: 60,
        percent: 16,
        type: 'finances'
      },
      cost: {
        value: 170,
        percent: 50,
        type: 'finances'
      },
      revenue: {
        value: 1000,
        percent: 100,
        type: 'finances'
      },
      timeSpent: {
        value: 10,
        percent: 100,
        type: 'finances'
      },
      profit: {
        value: 300,
        percent: 100,
        type: 'finances'
      },
    },
    {
      month: '',
      expenses: {
        value: 40,
        percent: 14,
        type: 'finances'
      },
      organizationExpenses: {
        value: 260,
        percent: 16,
        type: 'finances'
      },
      cost: {
        value: 340,
        percent: 50,
        type: 'finances'
      },
      revenue: {
        value: 1000,
        percent: 100,
        type: 'finances'
      },
      timeSpent: {
        value: 10,
        percent: 100,
        type: 'finances'
      },
      profit: {
        value: 300,
        percent: 100,
        type: 'finances'
      },
    },
    {
      month: '',
      expenses: {
        value: 240,
        percent: 14,
        type: 'finances'
      },
      organizationExpenses: {
        value: 260,
        percent: 16,
        type: 'finances'
      },
      cost: {
        value: 670,
        percent: 50,
        type: 'finances'
      },
      revenue: {
        value: 1000,
        percent: 100,
        type: 'finances'
      },
      timeSpent: {
        value: 10,
        percent: 100,
        type: 'finances'
      },
      profit: {
        value: 300,
        percent: 100,
        type: 'finances'
      },
    },
    {
      month: '',
      expenses: {
        value: 240,
        percent: 14,
        type: 'finances'
      },
      organizationExpenses: {
        value: 260,
        percent: 16,
        type: 'finances'
      },
      cost: {
        value: 1000,
        percent: 50,
        type: 'finances'
      },
      revenue: {
        value: 1000,
        percent: 100,
        type: 'finances'
      },
      timeSpent: {
        value: 10,
        percent: 100,
        type: 'finances'
      },
      profit: {
        value: 300,
        percent: 100,
        type: 'finances'
      },
    }
  ]

  table = plainToInstance(Table, {
    "header": [
      {
        "title": "ID",
        "slug": "id",
        "description": "ID description",
        "column": {
          "type": "field",
          "field": "name"
        },
        "filterForm": {
          "fields": [
            {
              "title": "Операнд",
              "slug": "operand",
              "type": "select",
              "configuration": {
                "dictionaryList": [
                  {
                    "id": "EQ",
                    "title": "Равно"
                  },
                  {
                    "id": "NEQ",
                    "title": "Не равно"
                  },
                  {
                    "id": "GTE",
                    "title": "Больше"
                  },
                  {
                    "id": "LTE",
                    "title": "Меньше"
                  },
                  {
                    "id": "BETWEEN",
                    "title": "Между"
                  }
                ]
              }
            },
            {
              "title": "ID",
              "slug": "id",
              "placeholder": "input",
              "type": "number",
              "configuration": {
                "minValue": 0,
                "between": false
              },
              "hideIf": {
                "field": "operand",
                "value": [
                  "BETWEEN"
                ]
              }
            },
            {
              "title": "ID",
              "slug": "id",
              "placeholder": "input",
              "type": "number",
              "configuration": {
                "minValue": 0,
                "between": true
              },
              "showIf": {
                "field": "operand",
                "value": [
                  "BETWEEN"
                ]
              }
            }
          ],
          "values": null
        },
        "sorting": {
          "slug": "id"
        },
        "selectionConfig": {
          "isSelectedByDefault": true,
          "isDisabled": false,
          "title": "Имя"
        },
        "dependencies": [
          "id"
        ]
      },
      {
        "title": "Общие",
        "slug": "general",
        "children": [
          {
            "title": "Имя",
            "slug": "name",
            "column": {
              "type": "field",
              "field": "name"
            },
            "filterForm": {
              "fields": [
                {
                  "title": "Операнд",
                  "slug": "operand",
                  "type": "select",
                  "configuration": {
                    "dictionaryList": [
                      {
                        "id": "EQ",
                        "title": "Равно"
                      },
                      {
                        "id": "NEQ",
                        "title": "Не равно"
                      }
                    ]
                  }
                },
                {
                  "title": "Имя",
                  "slug": "name",
                  "placeholder": "input",
                  "type": "text"
                }
              ],
              "values": null
            },
            "sorting": {
              "slug": "name"
            },
            "selectionConfig": {
              "isSelectedByDefault": false,
              "isDisabled": false,
              "title": "Имя"
            },
            "dependencies": [
              "name"
            ]
          },
          {
            "title": "Отчество",
            "slug": "surname",
            "column": {
              "type": "field",
              "field": "surname"
            },
            "filterForm": {
              "fields": [
                {
                  "title": "Операнд",
                  "slug": "operand",
                  "type": "select",
                  "configuration": {
                    "dictionaryList": [
                      {
                        "id": "EQ",
                        "title": "Равно"
                      },
                      {
                        "id": "NEQ",
                        "title": "Не равно"
                      }
                    ]
                  }
                },
                {
                  "title": "Отчество",
                  "slug": "surname",
                  "placeholder": "input",
                  "type": "text"
                }
              ],
              "values": null
            },
            "sorting": {
              "slug": "surname"
            },
            "selectionConfig": {
              "isSelectedByDefault": true,
              "isDisabled": false,
              "title": "Surname"
            },
            "dependencies": [
              "surname"
            ]
          },
          {
            "title": "Сотрудник",
            "slug": "worker",
            "column": {
              "type": "field",
              "field": "worker"
            },
            "filterForm": {
              "fields": [
                {
                  "title": "Операнд",
                  "slug": "operand",
                  "type": "select",
                  "configuration": {
                    "dictionaryList": [
                      {
                        "id": "EQ",
                        "title": "Равно"
                      },
                      {
                        "id": "NEQ",
                        "title": "Не равно"
                      }
                    ]
                  }
                },
                {
                  "title": "Сотрудник",
                  "slug": "worker",
                  "placeholder": null,
                  "type": "select",
                  "elementSize": "full",
                  "configuration": {
                    "multiple": false,
                    "gqlRequestConfig": {
                      "service": "admin",
                      "entity": "worker",
                      "searchByField": [
                        {
                          "column": "name",
                          "operator": "LIKE"
                        },
                        {
                          "column": "surname",
                          "operator": "LIKE"
                        }
                      ],
                      "searchByFieldOperator": "OR",
                      "fieldAsTitleSchema": "name",
                      "fieldAsId": "id",
                      "fields": [
                        "id",
                        "name"
                      ]
                    }
                  }
                }
              ],
              "values": null
            },
            "sorting": {
              "slug": "surname"
            },
            "selectionConfig": {
              "isSelectedByDefault": true,
              "isDisabled": false,
              "title": "Сотрудник"
            },
            "dependencies": [
              {
                "worker": [
                  "id",
                  "name"
                ]
              }
            ]
          },
          {
            "title": "Status",
            "slug": "status",
            "column": {
              "type": "field",
              "field": "status"
            },
            "filterForm": {
              "fields": [
                {
                  "title": "Операнд",
                  "slug": "operand",
                  "type": "select",
                  "configuration": {
                    "dictionaryList": [
                      {
                        "id": "EQ",
                        "title": "Равно"
                      },
                      {
                        "id": "NEQ",
                        "title": "Не равно"
                      }
                    ]
                  }
                },
                {
                  "title": "Статус",
                  "slug": "status",
                  "type": "select",
                  "configuration": {
                    "dictionaryList": [
                      {
                        "id": 1,
                        "title": "Активный"
                      },
                      {
                        "id": 0,
                        "title": "Не Активный"
                      }
                    ]
                  }
                }
              ],
              "values": null
            },
            "sorting": {
              "slug": "status"
            },
            "selectionConfig": {
              "isSelectedByDefault": true,
              "isDisabled": false,
              "title": "Статус"
            },
            "dependencies": [
              "status"
            ]
          }
        ]
      },
      {
        "title": "Meta",
        "slug": "meta",
        "dependencies": [
          {
            "worker": [
              {
                "metaFieldsWithValues": [
                  "slug",
                  "values"
                ]
              }
            ]
          }
        ],
        "children": [
          {
            "title": "Год рождения",
            "slug": "birth_date",
            "column": {
              "type": "meta",
              "field": "birth_date"
            },
            "filterForm": {
              "fields": [
                {
                  "title": "Операнд",
                  "slug": "operand",
                  "type": "select",
                  "configuration": {
                    "dictionaryList": [
                      {
                        "id": "EQ",
                        "title": "Равно"
                      },
                      {
                        "id": "NEQ",
                        "title": "Не равно"
                      },
                      {
                        "id": "GTE",
                        "title": "Больше"
                      },
                      {
                        "id": "LTE",
                        "title": "Меньше"
                      },
                      {
                        "id": "BETWEEN",
                        "title": "Между"
                      }
                    ]
                  }
                },
                {
                  "title": "Birth Date",
                  "slug": "date",
                  "placeholder": "00.00.0000",
                  "type": "date",
                  "configuration": {
                    "format": "dd.MM.yyyy",
                    "type": "date",
                    "between": false
                  },
                  "hideIf": {
                    "field": "operand",
                    "value": [
                      "BETWEEN"
                    ]
                  }
                },
                {
                  "title": "Birth Date",
                  "slug": "date",
                  "placeholder": "00.00.0000",
                  "type": "date",
                  "configuration": {
                    "format": "dd.MM.yyyy",
                    "type": "date",
                    "between": true
                  },
                  "showIf": {
                    "field": "operand",
                    "value": [
                      "BETWEEN"
                    ]
                  }
                }
              ],
              "values": null
            },
            "sorting": {
              "slug": "birth_date"
            },
            "selectionConfig": {
              "isSelectedByDefault": true,
              "isDisabled": false,
              "title": "Год рождения"
            }
          }
        ]
      },
      {
        "title": "Test Group",
        "slug": "test",
        "column": {
          "type": "field",
          "field": "test"
        },
        "filterForm": {
          "fields": [
            {
              "title": "Операнд",
              "slug": "operand",
              "type": "select",
              "configuration": {
                "dictionaryList": [
                  {
                    "id": "EQ",
                    "title": "Равно"
                  },
                  {
                    "id": "NEQ",
                    "title": "Не равно"
                  }
                ]
              }
            },
            {
              "title": "test",
              "slug": "test",
              "placeholder": "input",
              "type": "text"
            }
          ],
          "values": null
        },
        "sorting": {
          "slug": "test"
        },
        "selectionConfig": {
          "isSelectedByDefault": true,
          "isDisabled": false,
          "title": "test"
        },
        "children": [
          {
            "title": "test field 1",
            "slug": "test1"
          },
          {
            "title": "test field 2",
            "slug": "test2"
          },
          {
            "title": "test field 3",
            "slug": "test3"
          }
        ]
      }
    ],
    "footer": [
      {
        "title": "Для теста"
      }
    ],
    "dataGraph": {
      "additionalWhere": {},
      "fields": [
        "canEdit"
      ]
    },
    "rowsConfiguration": {
      "actionAvailable": [
        {
          "iconSchema": "burger",
          "subActions": [
            {
              "method": "edit",
              "methodSchema": "edit",
              "titleSchema": "edit",
              "iconSchema": "pencil",
              "availableSchema": "%%canEdit%%",
              "tooltipSchema": "edit"
            }
          ]
        }
      ],
      "cols": [
        {
          "slug": "id",
          "variableSchema": "%%id%%"
        },
        {
          "slug": "status",
          "variableSchema": "<span class='label **if('%%status%%', 'color-green', 'color-red')**'>**if('%%status%%', 'active', 'disabled')**</span>"
        },
        {
          "slug": "surname",
          "variableSchema": "%%surname%%"
        },
        {
          "slug": "name",
          "variableSchema": "%%name%%"
        },
        {
          "slug": "worker",
          "variableSchema": "<a href='**link('workerList', '%%worker.id%%')**'>**getMetaValue('name', '%%worker%%')**</>"
        },
        {
          "slug": "birth_date",
          "variableSchema": "**date(getMetaValue('birth_date', '%%self%%'), 'dd.MM.yyyy')**"
        },
        {
          "slug": "test1",
          "variableSchema": "test1"
        },
        {
          "slug": "test3",
          "variableSchema": "test2"
        },
        {
          "slug": "test2",
          "variableSchema": "test3"
        }
      ]
    },
    "tableConfiguration": {
      "tableInitialLoadTitle": "Загружаем пользователей",
      "tableLoadMoreTitle": "Загружаем сотрудников",
      "tableLoadMoreButtonTitle": "Загрузить еще",
      "stickLeft": 1,
      "stickRight": 1,
      "isStickHeader": true,
      "isStickFooter": true,
      "isColumnsConfigurable": true,
      "isShowActions": true
    },
    "userConfiguration": {
      "activeProfile": 1,
      "profiles": [
        {
          "id": 1,
          "title": "profile",
          "sorting": {
            "columnSlug": "id",
            "direction": "ASC"
          },
          "headerColumns": [
            {
              "slug": "id",
              "isActive": true,
              "order": 1
            },
            {
              "slug": "general",
              "isActive": true,
              "order": 3,
              "children": [
                {
                  "slug": "status",
                  "isActive": true,
                  "order": 1
                },
                {
                  "slug": "surname",
                  "isActive": false,
                  "order": 2
                },
                {
                  "slug": "name",
                  "isActive": true,
                  "order": 3
                }
              ]
            },
            {
              "slug": "meta",
              "isActive": true,
              "order": 2,
              "children": [
                {
                  "slug": "birth_date",
                  "isActive": true,
                  "order": 1
                }
              ]
            },
            {
              "slug": "test",
              "order": 5
            }
          ]
        }
      ]
    }
  });

  filterOptions: DataList[] = [
    {
      id: '0',
      label: 'Option 0',
      leaf: true,
      key: '0',
      children: []
    },
    {
      id: '1',
      label: 'Option 1',
      leaf: false,
      key: '1',
      children: [
        {
          id: '1-1',
          label: 'Option 1-1',
          leaf: true,
          key: '1-1',
          children: []
        },
        {
          id: '1-2',
          label: 'Option 1-2',
          leaf: true,
          key: '1-2',
          children: []
        },
        {
          id: '1-3',
          label: 'Option 1-3',
          leaf: true,
          key: '1-3',
          children: []
        },
      ]
    },
    {
      id: '2',
      label: 'Option 2',
      leaf: true,
      key: '2',
      children: []
    },
    {
      id: '3',
      label: 'Option 3',
      leaf: true,
      key: '3',
      children: []
    },
  ];

  ngAfterViewInit() {
    const fields = [
      ...this.generateFields(14),
    ];

    const form = plainToInstance(Form, {fields})
    console.log({form})
    this.formElement.build(form.fields, null);
  }

  generateRepeater(countRepeater: number, countGroup: number, countFields: number) {
    return Array(countRepeater).fill('').map((_, idd) => {
      return {
        slug: 'repeater-'+idd,
        elementConfiguration: {
          type: 'repeater',
          configuration: {
            fields:this.generateGroup(countGroup, countFields)
          }
        }
      }
    })
  }
  generateGroup(countGroup: number, countFields: number) {
    return Array(countGroup).fill('').map((_, id) => {
      return {
        slug: 'group-'+id,
        elementConfiguration: {
          type: 'group',
          configuration: {
            fields: this.generateFields(countFields)
          },
        }
      }
    })
  }

  generateFields(count: number) {
    let i = 0;
    return Array(count).fill('').map((item, idx) => {
      if (i >= 14) {
        console.log({i})
        i = 0;
      }

      switch (i) {
        case 0:
          i++;
          return {

            slug: 'field-'+idx,
            elementConfiguration: {
              title: 'Text ' + idx,
              type: 'text',
              configuration: {
                type: 'text',
                clearable: true
              },
            }
          };
        case 1:
          i++;
          return {

            slug: 'field-'+idx,
            elementConfiguration: {
              title: 'Number min-max ' + idx,
              type: 'number',
              configuration: {
                type: 'number',
                between: false,
                maxValue: 1000,
                minValue: 0,
                clearable: true
              },
            }
          };
        case 2:
          i++;
          return {
            slug: 'field-'+idx,
            elementConfiguration: {
              type: 'date',
              title: 'Date ' + idx,
              configuration:  {
                between: false,
              },
            },
            elementConfigurationReplace: [
              {
                applyIf: [
                  {
                    field: 'field-1',
                    value: ['between-date']
                  }
                ],
                isFullReplace: false,
                toReplace: {
                  configuration: {
                    between: true
                  }
                }
              }
            ]
          };
        case 3:
          i++;
          return {
            slug: 'field-'+idx,
            hideIf: [
              {
                field: 'field-0',
                value: ['hide-'+idx]
              }
            ],
            elementConfiguration: {
              type: 'number',
              title: 'Number ' + idx,
              configuration: {
                type: 'number',
                between: true,
                maxValue: 1230,
                minValue: 100,
              },
            }
          };
        case 4:
          i++;
          return {
            slug: 'field-'+idx,
            hideIf: idx > 2 && idx % 3 === 0 ? [
              {
                field: 'field-'+(idx-2),
                value:  ['hide-'+idx]
              }
            ] : null,
            elementConfiguration: {
              type: 'textarea',
              title: 'Textarea ' + idx,
              validation: [
                {
                  type: 'required',
                  text: 'Required field'
                }
              ]
            },
            elementConfigurationReplace: [
              {
                applyIf: [
                  {
                    field: 'field-1',
                    value: ['change-textarea-to-number']
                  }
                ],
                isFullReplace: true,
                toReplace: {
                  type: 'number',
                  title: 'Number ' + idx,
                  configuration: {
                    type: 'number'
                  },
                  validation: [
                    {
                      type: 'required',
                      text: 'Required field'
                    }
                  ]
                }
              }
            ]
          };
        case 5:
          i++;
          return {
            slug: 'field-'+idx,
            hideIf: idx > 3 && idx % 5 === 0 ? [
              {
                field: 'field-'+(idx-5),
                value: ['hide-'+idx]
              }
            ] : null,
            elementConfiguration: {
              type: 'text',
              title: 'Replaced ' + idx,
              placeholder: 'Введите текст',
              configuration: {
                type: 'text'
              },
              validation: [
                {
                  type: 'required',
                  text: 'Required field'
                }
              ],
            },
            elementConfigurationReplace: [
              {
                applyIf: [
                  {
                    field: 'field-0',
                    value: ['replace 6 to phone']
                  }
                ],
                isFullReplace: false,
                toReplace: {
                  configuration: {
                    type: 'phone',
                    mask: '(###) ###-##-##',
                    countryCode: '+7'
                  }
                }
              }
            ],
          };
        case 6:
          i++;
          return {

            slug: 'field-'+idx,
            elementConfiguration: {
              title: 'Password ' + idx,
              type: 'password',
              configuration: {
                type: 'password',
              },
            }
          };
        case 7:
          i++;
          return {
            slug: 'field-'+idx,
            elementConfiguration: {
              title: 'Date ' + idx,
              type: 'date',
              configuration: {
                minDate: (new Date()).toISOString(),
                clearable: true,
              },
            }
          };
        case 8:
          i++;
          return {
            slug: 'field-'+idx,
            elementConfiguration: {
              title: 'Date ' + idx,
              type: 'date',
              configuration: {
                type: 'datetime',
                clearable: true,
                minDate: (new Date()).toISOString(),
                maxDate: (new Date(1728761856917)).toISOString(),
              },
            }
          };
        case 9:
          i++;
          return {
            slug: 'field-'+idx,
            elementConfiguration: {
              title: 'Date ' + idx,
              type: 'date',
              configuration: {
                clearable: true,
                between: true,
                minDate: (new Date()).toISOString(),
                maxDate: (new Date(1728761856917)).toISOString(),
              },
            }
          };
        case 10:
          i++;
          return {
            slug: 'field-'+idx,
            elementConfiguration: {
              title: 'Date ' + idx,
              type: 'date',
              configuration: {
                clearable: true,
                type: 'datetime',
                between: true,
                minDate: (new Date()).toISOString(),
                maxDate: (new Date(1728761856917)).toISOString(),
              },
            }
          };
        case 11:
          i++;
          return {
            slug: 'field-'+idx,
            elementConfiguration: {
              title: 'Select ' + idx,
              type: 'select',
              configuration: {
                clearable: true,
                dataList: <DataList[]>[
                  {
                    id: '0',
                    label: 'Option 0',
                    leaf: true,
                    key: '0',
                    children: []
                  },
                  {
                    id: '1',
                    label: 'Option 1',
                    leaf: false,
                    key: '1',
                    children: [
                      {
                        id: '1-1',
                        label: 'Option 1-1',
                        leaf: true,
                        key: '1-1',
                        children: []
                      },
                      {
                        id: '1-2',
                        label: 'Option 1-2',
                        leaf: true,
                        key: '1-2',
                        children: []
                      },
                      {
                        id: '1-3',
                        label: 'Option 1-3',
                        leaf: true,
                        key: '1-3',
                        children: []
                      },
                    ]
                  },
                  {
                    id: '2',
                    label: 'Option 2',
                    leaf: true,
                    key: '2',
                    children: []
                  },
                  {
                    id: '3',
                    label: 'Option 3',
                    leaf: true,
                    key: '3',
                    children: []
                  },
                ]
              },
            }
          };
        case 12:
          i++;
          return {
            slug: 'field-'+idx,
            elementConfiguration: {
              title: 'Select ' + idx,
              type: 'select',
              configuration: {
                clearable: true,
                multiple: true,
                dataList: <DataList[]>[
                  {
                    id: '0',
                    label: 'Option 0',
                    leaf: true,
                    key: '0',
                    children: []
                  },
                  {
                    id: '1',
                    label: 'Option 1',
                    leaf: false,
                    key: '1',
                    children: [
                      {
                        id: '1-1',
                        label: 'Option 1-1',
                        leaf: true,
                        key: '1-1',
                        children: []
                      },
                      {
                        id: '1-2',
                        label: 'Option 1-2',
                        leaf: true,
                        key: '1-2',
                        children: []
                      },
                      {
                        id: '1-3',
                        label: 'Option 1-3',
                        leaf: true,
                        key: '1-3',
                        children: []
                      },
                    ]
                  },
                  {
                    id: '2',
                    label: 'Option 2',
                    leaf: true,
                    key: '2',
                    children: []
                  },
                  {
                    id: '3',
                    label: 'Option 3',
                    leaf: true,
                    key: '3',
                    children: []
                  },
                ]
              },
            }
          };
        case 13:
          i++;
          return {
            slug: 'field-'+idx,
            elementConfiguration: {
              title: 'Checkbox ' + idx,
              type: 'checkbox',
              configuration: {
                dataList: <DataList[]>[
                  {
                    id: '0',
                    label: 'Option 0',
                    leaf: false,
                    key: '0',
                    children: []
                  },
                  {
                    id: '1',
                    label: 'Option 1',
                    leaf: false,
                    key: '1',
                    children: [
                      {
                        id: '1-1',
                        label: 'Option 1-1',
                        leaf: false,
                        key: '1-1',
                        children: []
                      },
                      {
                        id: '1-2',
                        label: 'Option 1-2',
                        leaf: false,
                        key: '1-2',
                        children: []
                      },
                      {
                        id: '1-3',
                        label: 'Option 1-3',
                        leaf: false,
                        key: '1-3',
                        children: []
                      },
                    ]
                  },
                  {
                    id: '2',
                    label: 'Option 2',
                    leaf: false,
                    key: '2',
                    children: []
                  },
                  {
                    id: '3',
                    label: 'Option 3',
                    leaf: false,
                    key: '3',
                    children: []
                  },
                ]
              },
            }
          };
      }
      return {}
    })
  }

  openModal(size: 'medium' | 'large') {
    this.isLoadingModal.set(true);
    setTimeout(() => {
      this.isLoadingModal.set(false);
    }, 1500)

    this.modalService.open({
      dynamicComponent: CommonModalComponent,
      size: size,
      data: {
        title: 'Blank Component',
        isLoading: this.isLoadingModal,
        buttons: [
          {
            settings: {
              title: 'Close',
              appearance: 'primary'
            },
            isClose: true,
          }
        ],
        callbackBeforeClose: (modal: CommonModalComponent) => {
          console.log('callbackBeforeClose', {modal})
          return true;
        },
        callbackAfterClose: (modal: CommonModalComponent) => {
          console.log('callbackAfterClose', {modal})
        }
      }
    });

  }

  openAlert(appearance: TuiAppearanceOptions['appearance'] = 'warning') {
    this.alerts
      .open<boolean>(new PolymorpheusComponent(AlertComponent), {
        label: 'Title',
        data: <IAlert>{
          text: 'text'
        },
        appearance,
      })
      .subscribe(response => {
        console.log({response})
      });
  }

  setBlur() {
    if (this.isBlur) {
      return;
    }
    this.isBlur = true;
    setTimeout(() => {
      this.isBlur = false;
    }, 1000)
  }

  api() {
    this.employeeService.get(undefined, {isDataList: true}).subscribe(res => {
      console.log({res})
    })
  }
}
