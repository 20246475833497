import {Component, inject} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {
  TransactionReusablePageComponent
} from '@/app/components/reusable-page/transaction-reusable-page/item.component';
@Component({
  selector: 'app-project-transactions-page',
  standalone: true,
  imports: [
    TransactionReusablePageComponent
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
})
export class ProjectTransactionsPageComponent {
  aRoute = inject(ActivatedRoute);
  projectId: string;

  constructor() {
    this.aRoute.parent?.params?.subscribe(params => {
      if (params['projectId']) {
        this.projectId = params['projectId'];
      }
    })
  }
}
