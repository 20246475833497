import {
  Component, CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import {PageWrapperComponent} from '@/app/components/page/page-wrapper/item.component';

import {
  TransactionReusablePageComponent
} from '@/app/components/reusable-page/transaction-reusable-page/item.component';

@Component({
  selector: 'app-transaction-page',
  standalone: true,
  imports: [
    PageWrapperComponent,
    TransactionReusablePageComponent
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TransactionPageComponent {

}
