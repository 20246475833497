import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, effect, HostBinding, inject, Injector, Input, OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {TuiButton, TuiScrollbar} from '@taiga-ui/core';
import {IButtonModalData, IModalData, ModalType} from '@/app/modals/type/modal';
import {TuiPopover} from '@taiga-ui/cdk';
import {TuiButtonLoading, TuiSkeleton} from '@taiga-ui/kit';
import {FormElementComponent} from '@/app/components/form-components/form-constructor/form-element/item';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {toObservable} from '@angular/core/rxjs-interop';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-common-modal',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TuiButton,
    TuiScrollbar,
    TuiButtonLoading,
    FormElementComponent,
    ReactiveFormsModule,
    TuiSkeleton
  ]
})
export class CommonModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() context!: TuiPopover<ModalType<IModalData<CommonModalComponent>>, string>;
  @HostBinding('class.modal-parent') setParent() {
    return true;
  }

  @ViewChild('formElement') formElement: FormElementComponent;
  form = new FormControl();
  injector = inject(Injector);
  makeAllFormTouched = false;
  isAsyncFormLoading = false;
  sub: Subscription;

  ngOnInit() {
    if (this.context.data.asyncForm) {
      this.isAsyncFormLoading = true;
    }
  }

  ngAfterViewInit() {
    if (this.context.data.form) {
      this.formElement.build(this.context.data.form.fields, this.context.data.form?.value ?? null);
    }

    if (this.context.data.asyncForm) {
      this.sub = toObservable(this.context.data.asyncForm, {injector: this.injector}).subscribe(form => {
        if (form) {
          this.isAsyncFormLoading = false;
          this.formElement.build(form.fields, form?.value ?? null);
        }
      })
    }
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  close() {
    if (this.context.data.asyncForm) {
      this.context.data.asyncForm.set(null);
    }
    this.context.$implicit.complete();
    if (this.context.data.callbackAfterClose) {
      this.context.data.callbackAfterClose(this)
    }
  }

  onClick(btn: IButtonModalData<CommonModalComponent>) {
    if (btn.callback) {
      btn.callback(this);
    }
    if (btn.isClose) {
      if (this.context.data.callbackBeforeClose) {
        if (this.context.data.callbackBeforeClose(this, btn)) {
          this.close();
        }
      } else {
        this.close();
      }
    }
  }
}
