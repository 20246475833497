import {Inject, Injectable} from '@angular/core';
import {ParentModalService} from '@/app/modals/service/parent-modal.service';
import {IButtonModalData, IModalData, ModalType} from '@/app/modals/type/modal';
import {CommonModalComponent} from '@/app/modals/components/common/item';
import {IConfirmModal} from '@/app/modals/type/confirm-modal';
@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    @Inject(ParentModalService) private readonly parentModalService: ParentModalService,
  ) { }

  open(data: ModalType<IModalData<any>>) {
    this.parentModalService.open(null, data)
      .subscribe(response => {
        console.log({response})
      });
  }

  openConfirm(data: IConfirmModal) {
    this.parentModalService.open(null, {
      dynamicComponent: CommonModalComponent,
      size: 'medium',
      data: {
        title: data.title,
        text: data.text,
        buttons: [
          {
            settings: {
              title: data.cancelLabel ?? 'Cancel',
              appearance: 'flat',
            },
            isClose: true,
            callback: () => {
              if (data.onCancel) {
                data.onCancel();
              }
            }
          },
          {
            settings: {
              title: data.confirmLabel,
              appearance: 'primary',
            },
            isClose: true,
            callback: () => {
              data.onConfirm();
            }
          }
        ],
      }
    }).subscribe(response => {})
  }
}
