import {CommonModalComponent} from '@/app/modals/components/common/item';
import {plainToInstance} from 'class-transformer';
import {Form} from '@/app/entities/form/form.entity';
import {projectFormConfig} from '@/app/modals/form-config/project.cfg';
import {IButtonModalData} from '@/app/modals/type/modal';
import {ModalService} from '@/app/modals/service/modal.service';
import {NodeService} from '@/app/services/api/node.service';
import {DataList} from '@/app/entities/common/data-list.entity';
import {ProjectService} from '@/app/services/page-utils/project.service';
import {Node} from '@/app/entities/nodes/node.entity';

export const openIssueModal =
  (
    modalService: ModalService,
    nodeService: NodeService,
    projectService: ProjectService,
    projectList?: DataList[],
    parentId?: number,
    formValue?: any,
    isProject?: boolean,
    updatedNode?: Node
  ) =>
{
  modalService.open({
    dynamicComponent: CommonModalComponent,
    size: 'large',
    data: {
      title: formValue ? `Edit ${isProject ? 'project' : 'issue'}`  : 'Add issue',
      form: plainToInstance(Form, {
        fields: [
          ...projectFormConfig(projectList)
        ],
        value: formValue ? formValue : {}
      }),
      isLoading: projectService.loading,
      buttons: [
        {
          settings: {
            title: 'Cancel',
            appearance: 'flat',
          },
          isClose: true,
        },
        {
          settings: {
            title: 'Save',
            appearance: 'primary',
          },
          isClose: true,
        }
      ],
      callbackBeforeClose: (modal: CommonModalComponent, btn: IButtonModalData<CommonModalComponent>) => {
        if (btn.settings.title === 'Cancel') {
          return true;
        }

        modal.makeAllFormTouched = true;
        modal.formElement.getValueWithValidData();

        setTimeout(() => {
          if (!modal.formElement.formGroup.invalid) {
            const {parentNodeId, code, title, description, billingModel, price, hourRate} = modal.form.value;
            projectService.loading.set(true);
            nodeService.update([
              {
                ...updatedNode && updatedNode,
                title,
                code,
                description,
                parentNodeId: parentNodeId ? parentNodeId : parentId ? parentId : null,
                settingsSelf: {
                  billingModel,
                  price: billingModel === 'fix' ? price : null,
                  hourRate: billingModel === 'time_and_material' ? hourRate : null,
                  estimation: null,
                  fromNodeId: null
                },
                ...!updatedNode && {type: isProject ? 'project' : 'task'}
              }
            ]).subscribe({
              next: res => {
                if (res.isSuccess) {
                  modal.close();
                  if (isProject) {
                    projectService.updateProject$.next();
                  } else {
                    projectService.updateNode$.next();
                  }
                }
                projectService.loading.set(false);
              },
              error: err => {
                projectService.loading.set(false);
              }
            })
          }
        })

        return false;
      },
    }
  });
}
