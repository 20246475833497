import {Component, inject} from '@angular/core';
import {EmployeesReusablePageComponent} from '@/app/components/reusable-page/employees-reusable-page/item.component';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-project-employees-page',
  standalone: true,
  imports: [
    EmployeesReusablePageComponent
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
})
export class ProjectEmployeesPageComponent {
  aRoute = inject(ActivatedRoute);
  projectId: string;

  constructor() {
    this.aRoute.parent?.params?.subscribe(params => {
      if (params['projectId']) {
        this.projectId = params['projectId'];
      }
    })
  }
}
