<tui-radio-list
    [formControl]="control"
    [itemContent]="content"
    [style.flex-direction]="direction"
    [items]="items" />
<ng-template
  #content
  let-data
>
    <span tuiTitle>
        {{ data.label }}
    </span>
</ng-template>
