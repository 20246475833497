import {Component, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {PageWrapperComponent} from '@/app/components/page/page-wrapper/item.component';
import {EmployeesReusablePageComponent} from '@/app/components/reusable-page/employees-reusable-page/item.component';


@Component({
  selector: 'app-employees-page',
  standalone: true,
  imports: [
    PageWrapperComponent,
    EmployeesReusablePageComponent
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EmployeesPageComponent  {
}
