import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, EventEmitter,
  forwardRef, inject, Injector,
  Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild,

} from '@angular/core';

import {FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {AbstractNgModelComponent} from '@/app/core/abstract/ng-model.component';
import {Subscription} from 'rxjs';
import {DataList} from '@/app/entities/common/data-list.entity';
import {AutoFocus} from 'primeng/autofocus';
import {ChipModule} from 'primeng/chip';
import {InputTextModule} from 'primeng/inputtext';
import {NgClass, NgTemplateOutlet} from '@angular/common';
import {PrimeTemplate, TreeNode} from 'primeng/api';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {Tree, TreeModule} from 'primeng/tree';
import {TUI_DARK_MODE, TuiButton, TuiIcon} from '@taiga-ui/core';
import {TuiSheetDialog} from '@taiga-ui/addon-mobile';
import {BreakpointObserver} from '@angular/cdk/layout';
import {debounceTime} from 'rxjs/operators';
import {SelectOverlayWrapperComponent} from '@/app/components/form-components/select/overlay-wrapper/item';
import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  CloseScrollStrategy, ConnectedOverlayPositionChange,
  ConnectionPositionPair,
  Overlay
} from '@angular/cdk/overlay';

/**
 * Represents a select component with options for single and multiple selection.
 *
 * @example
 *
 * <app-select
 *  [FormControl]='control'
 *  [treeItems]='[]'
 *  [type]="'single'"
 *></app-select>
 */
@Component({
  selector: 'app-select',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ],

  imports: [
    ReactiveFormsModule,
    AutoFocus,
    ChipModule,
    InputTextModule,
    NgTemplateOutlet,
    PrimeTemplate,
    ProgressSpinnerModule,
    TreeModule,
    TuiButton,
    TuiSheetDialog,
    NgClass,
    SelectOverlayWrapperComponent,
    CdkConnectedOverlay,
    CdkOverlayOrigin,
    TuiIcon,
  ]
})
export class SelectComponent extends AbstractNgModelComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild(CdkOverlayOrigin) origin!: CdkOverlayOrigin;
  @ViewChild(CdkConnectedOverlay) connectedOverlay!: CdkConnectedOverlay;
  @ViewChild(SelectOverlayWrapperComponent) overlayWrapper!: SelectOverlayWrapperComponent;
  @ViewChild('tree') treeRef: Tree;

  /**
   * Header template ref
   */
  @Input() selectHeader: TemplateRef<any>;
  /**
   * Option template ref
   */
  @Input() selectOption: TemplateRef<any>;

  @Input() parentSlug: string;
  @Input() items: DataList[] = [];
  @Input() multiple: boolean = false;
  @Input() isSearch: boolean = true;
  @Input() clearable: boolean;
  @Input() loading = false;

  @Output() onLoadMore = new EventEmitter<void>();
  @Output() onSearch = new EventEmitter<string>();

  sub: Subscription;

  control = new FormControl<DataList[] | DataList | null>(null);
  selected: any;
  scrollStrategy: CloseScrollStrategy;
  isMobile: boolean;

  currentScrollHeight: number = 0;
  isFirstLoad = true;
  private mutationObserver: MutationObserver;

  search = new FormControl();
  totalItems: number = 0;
  isOpen:boolean = false;
  isTop:boolean = false;
  positions:ConnectionPositionPair[] = [
    new ConnectionPositionPair(
      { originX: 'center', originY: 'top' },
      { overlayX: 'center', overlayY: 'top' },
      0,
      0,
      'select-body-bottom'
    ),
    new ConnectionPositionPair(
      { originX: 'center', originY: 'bottom' },
      { overlayX: 'center', overlayY: 'bottom' },
      0,
      0,
      'select-body-top'
    ),
  ]


  constructor(
    injector: Injector,
    private overlay:Overlay,
    private breakpointObserver: BreakpointObserver,
  ) {
    super(injector);

    this.scrollStrategy = this.overlay.scrollStrategies.close();
    this.isMobile = this.breakpointObserver.isMatched('(max-width: 599px)');
  }
  ngOnInit() {
    this.currentScrollHeight = this.parentEl.scrollHeight;
    this.cdRef.markForCheck();

    this.sub = this.search.valueChanges
      .pipe(
        debounceTime(300),
      ).subscribe(search => {
    });
  }

  ngAfterViewInit() {
    // This for update overlay position
    this.mutationObserver = new MutationObserver(() => {
      if (this.parentEl.scrollHeight !== this.currentScrollHeight && this.connectedOverlay && this.connectedOverlay.overlayRef) {
        this.currentScrollHeight = this.parentEl.scrollHeight;
        this.connectedOverlay.overlayRef.updatePosition();
      }
    });

    this.mutationObserver.observe(this.parentEl, {
      childList: true,
      subtree: true,
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    this.mutationObserver.disconnect();
  }

  get parentEl() {
    return document.querySelector('.hat-scaffold__body-content') || document.documentElement;
  }
  /**
   * Retrieves the width of the origin element.
   *
   * @return {number} The width of the origin element, or 0 if the element is undefined or has no width.
   */
  get originWidth():number {
    const element = this.origin?.elementRef?.nativeElement as HTMLElement|undefined;
    return element?.offsetWidth??0;
  }


  /**
   * Toggles the state of an element.
   * If the element is disabled, it does not toggle the state.
   *
   *
   * @returns {void}
   */
  toggle() {
    if(this.disabled || this.readonly) return
    this.isOpen = !this.isOpen;
    const selected = Array.isArray(this.selected) ? this.selected[0] : this.selected;

    setTimeout(() => {
      if (this.treeRef?.filterViewChild?.nativeElement) {
        this.treeRef.filterViewChild.nativeElement?.focus();
      }
    })

    if (this.isOpen && selected && selected.key && !this.search.value) {
      setTimeout(() => {
        if (this.treeRef && !this.treeRef.filteredNodes?.length) {
          // this.treeItems.forEach(item => {
          //   this.expandRecursive(item)
          // });

          if (this.treeRef.serializedValue && this.treeRef.serializedValue.length > 5) {
            this.goToItem(selected.key);
          }
        }
        this.cdRef.markForCheck();
      })
    }
  }


  /**
   * Sets the isOpen property to false.
   */
  onClose() {
    this.isOpen = false;
    if (this.search.value) {
      this.search.setValue(null);
    }
  }


  /**
   * Handles the position change of the connected overlay.
   *
   * @param {ConnectedOverlayPositionChange} change - The position change event.
   */
  onPositionChange(change:ConnectedOverlayPositionChange) {
    this.isTop = change.connectionPair.overlayY!='top';
    this.cdRef.detectChanges();
  }


  /**
   * Invokes the onChangeModel method passing the selected value
   * and logs the selected value to the console.
   *
   * @method select
   *
   */
  select() {
    if (!this.multiple && this.selected && this.overlayWrapper) {
      this.overlayWrapper.close();
    }

    if (this.onChangeModel) {
      this.onChangeModel(this.selected);
      this.onTouchModel();
    }
  }


  /**
   * Removes an item from the selected list.
   *
   * @param {any} ev - The event that triggered the removal.
   * @param {TreeNode} item - The item to be removed.
   */
  remove(ev: any, item: TreeNode) {
    ev.stopPropagation();
    ev.preventDefault();
    this.selected = this.selected.filter(select => select.key !== item.key);
    if (this.onChangeModel) {
      this.onChangeModel(!this.selected.length ? null : this.selected);
    }
  }


  /**
   * Remove single item from the selection.
   *
   * @param {any} ev - The event object.
   */
  removeSingle(ev: any) {
    ev.stopPropagation();
    ev.preventDefault();

    this.selected = null
    if (this.onChangeModel) {
      this.onChangeModel(this.selected);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const { multiple, items } = changes;

    if (multiple && !multiple.firstChange && this.selected && multiple.currentValue !== multiple.previousValue) {
      if (!multiple.currentValue) {
        this.selected = this.selected[0];
      } else  {
        this.selected = [this.selected];
      }
      this.select()
    }

    if (items && JSON.stringify(items?.currentValue) !== JSON.stringify(items?.previousValue)) {
      this.totalItems = this.getTotalItems();
    }
  }

  override writeValue(value: any) {
    super.writeValue(value);
    if (value) {
      this.selected = !this.multiple ? value : [...value];
      this.nodeGetValue();
    } else {
      this.selected = null
    }
    this.cdRef.detectChanges();
  }

  nodeGetValue() {
    if (!this.isFirstLoad) {
      return;
    }
    this.isFirstLoad = false;
    const selectedKeys = !this.selected ? null : Array.isArray(this.selected) ? this.selected : [this.selected];

    const selected = selectedKeys?.reduce((acc, key) => {
      const item = this.items.find(item => item.key === key);
      acc.push(item);
      return acc;
    }, [])

    if (selectedKeys) {
      this.selected = !this.multiple ? selected[0] : selected;
      setTimeout(() => {
        this.select();
      }, 100);
    }
  }

  nodeExpand(event: any) {
    if (event.node.children && event.node.children.length) {
      if (this.treeRef) {
        this.goToItem(event.node.key)
        this.cdRef.markForCheck();
      }
      return;
    }

    if (event.node.leaf) {
      return;
    }
  }

  goToItem(key: string, callback?: () => void) {
    const idx = this.treeRef.serializedValue && this.treeRef.serializedValue.findIndex((item: any) => item.node.key === key);
    if (!idx || idx < 0 || this.treeRef.filteredNodes?.length) {
      return;
    }
    setTimeout(() => {
      this.treeRef.scrollToVirtualIndex(idx!);
      this.treeRef.updateSerializedValue();
      if (callback) {
        callback()
      }
      this.cdRef.markForCheck();
    })
  }

  expandRecursive(node: TreeNode) {
    if (node.children && node.children.length) {
      node.expanded = true;
      node.children.forEach((childNode) => {
        this.expandRecursive(childNode);
      });
      this.treeRef.updateSerializedValue();
      this.cdRef.markForCheck();
    }
  }

  getLabel(item: DataList) {
    return item.label
  }

  getTotalItems() {
    let count = 0;

    const walk = (elements) => {
      if (count > 6) {
        return;
      }
      for (let i = 0; i < elements.length; i++) {
        count++;
        if (elements[i].children?.length) {
          walk(elements[i].children);
        }
      }
    };

    walk(this.items);

    return count;
  }

  trackByIndex(idx: number): number {
    return idx;
  }

  get hasSelected(): boolean {
    return this.selected
      ? Array.isArray(this.selected)
        ? !!this.selected.length
        : true
      : false;
  }
}
