import {DataList} from '@/app/entities/common/data-list.entity';

export const transactionFormConfig = (
  categoryItems: DataList[],
  nodeItems?: DataList[],
) => {
  const projectField = nodeItems ? {
    slug: 'nodeId',
    elementConfiguration: {
      title: 'Project/Issue',
      type: 'select',
      configuration: {
        clearable: true,
        search: true,
        dataList: nodeItems
      },
      validation: [
        {
          type: 'required',
          text: 'Required field'
        }
      ],
    }
  } : undefined;

  let fields: any[] = [
    {
      slug: 'type',
      elementConfiguration: {
        type: 'radio',
        configuration: {
          direction: 'row',
          dataList: <DataList[]>[
            {
              id: 'outcome',
              label: 'Outcome',
            },
            {
              id: 'income',
              label: 'Income',
            }
          ],
        },
        validation: [
          {
            type: 'required',
            text: 'Required field'
          }
        ],
      }
    },
    {
      slug: 'amount',
      elementConfiguration: {
        title: 'Amount',
        type: 'number',
        configuration: {
          type: 'number',
          between: false,
          minValue: 0,
          clearable: true,
          prefix: '$ '
        },
        validation: [
          {
            type: 'required',
            text: 'Required field'
          }
        ],
      }
    },
    {
      slug: 'date',
      elementConfiguration: {
        title: 'Date',
        type: 'date',
        configuration:  {
          between: false,
          clearable: true,
        },
        validation: [
          {
            type: 'required',
            text: 'Required field'
          }
        ],
      }
    },
    {
      slug: 'title',
      elementConfiguration: {
        title: 'Title',
        type: 'text',
        configuration:  {
          type: 'text',
          clearable: true,
        },
        validation: [
          {
            type: 'required',
            text: 'Required field'
          }
        ],
      }
    },
    {
      slug: 'description',
      elementConfiguration: {
        title: 'Description',
        type: 'textarea',
        validation: [
          {
            type: 'required',
            text: 'Required field'
          }
        ],
      },
    },
    {
      slug: 'categoryId',
      elementConfiguration: {
        title: 'Category',
        type: 'select',
        configuration: {
          clearable: true,
          search: false,
          dataList: categoryItems
        },
        validation: [
          {
            type: 'required',
            text: 'Required field'
          }
        ],
      }
    }
  ];

  if (projectField) {
    fields.push(projectField);
  }

  return fields;
}
