@if (firstLoading) {
  <app-table-skeleton [buttonsSize]="['w-2rem', 'w-2rem']" [isHideSearch]="true"/>
} @else {
  <app-table
      [tableConfig]="tableCfg"
      [tableRows]="tableData"
      [isLoading]="tableLoading"
      [isVirtual]="true"
      [controlButton]="tableButtons"
      [controlIsHideSearch]="true"
  />
}

<ng-template #tableDateFilter>
  <app-table-date-filter (onDateChange)="onDateChange($event)" />
</ng-template>
