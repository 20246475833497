import {
  ChangeDetectionStrategy,
  Component, EventEmitter,
  forwardRef,
  Input,
  OnChanges, OnDestroy,
  OnInit, Output,
  SimpleChanges,
} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {AbstractNgModelComponent} from '@/app/core/abstract/ng-model.component';
import {Subscription} from 'rxjs';
import {DataList} from '@/app/entities/common/data-list.entity';
import {FormElementSelectConfiguration} from '@/app/entities/form/form-element.entity';
import {SelectComponent} from '@/app/components/form-components/select/item';

@Component({
  selector: 'app-form-select',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormSelectComponent),
      multi: true,
    },
  ],
  imports: [
    SelectComponent,
    ReactiveFormsModule
  ]
})
export class FormSelectComponent extends AbstractNgModelComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * Parent loader state
   */
  @Input() loader: boolean = false;

  @Input() elementConfig: any;

  @Input() parentSlug: string;

  @Output() change: EventEmitter<void> = new EventEmitter<void>();

  select = new FormControl<any>(null);

  paginator: { currentPage: number, hasMorePages: boolean, search: string | null, total: number } | null;

  isSearchByDefault = false;
  isFirstLoad = true;
  loading = false;
  dataList: DataList[] = [];

  // constructor(
  //   injector: Injector,
  //   fb: FormBuilder,
  //   // dictionaryListService: DictionaryListService
  // ) {
  //   super(injector);
  //   this.fb = fb;
  //   // this.dictionaryListService = dictionaryListService;
  // }

  sub: Subscription;

  ngOnInit() {
    this.buildOneCollection();

    this.sub = this.select.valueChanges
        .pipe(debounceTime(100))
        .subscribe(v => {
          if (this.onChangeModel) {
            let value: any = null;
            if (Array.isArray(v)) {
              value = v.map((item) => item.id ?? item.key);
            } else if (v) {
              value = v.id ?? v.key;
            }
            this.onChangeModel(value);
            this.onTouchModel();
          }
        });
  }

  ngOnChanges(changes: SimpleChanges) {
    const { elementConfig } = changes;
    if (elementConfig && !elementConfig.firstChange) {
      this.dataList = [];
      this.paginator = null;
      this.isFirstLoad = true;
      this.buildOneCollection();
      this.cdRef.markForCheck();
    }
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  get selectCfg(): FormElementSelectConfiguration {
    return this.elementConfig.configuration as FormElementSelectConfiguration;
  }

  override writeValue(value: any) {
    super.writeValue(value);
    this.select.setValue(value, { emitEvent: false });
  }

  buildOneCollection() {
    const cfg = this.elementConfig.configuration as FormElementSelectConfiguration;

    if (cfg.dataList?.length) {
      this.dataList = cfg.dataList;
      this.isSearchByDefault = true;
      this.cdRef.markForCheck();
    } else if (cfg.urlToSearch) {
      this.loadDataList()
      this.isSearchByDefault = false;
      this.cdRef.markForCheck();
    }
  }

  loadDataList() {
      // TODO: make load data from API
  }

  loadGqlDictionaryList(page: number = 1, search: string = '') {
    // this.loading = true;
    // this.cdRef.markForCheck();
    // this.dictionaryListService.getDictionaryListByGql(
    //   this.elementConfig.configuration.gqlRequestConfig,
    //   page,
    //   search,
    //   false,
    //   this.paginator.total ?? 1000,
    //   (list, pagination) => {
    //
    //     if (this.isFirstLoad) {
    //       this.isGQL = pagination.hasMorePages;
    //     }
    //
    //     this.isFirstLoad = false;
    //     const lastTree = [...this.treeNode];
    //     this.isSearchByDefault = search ? false : this.lastSearch ? false : !pagination.hasMorePages;
    //     if (pagination?.hasMorePages) {
    //       list.push({
    //         key: 'morePageLoad',
    //         selectable: false,
    //         type: 'loadMore',
    //         label: 'Загрузить еще'
    //       })
    //     }
    //
    //     if (this.paginator?.hasMorePages) {
    //       lastTree.splice(-1, 1);
    //     }
    //
    //     this.paginator = pagination;
    //     this.treeNode = search ? [...list] : this.lastSearch ? [...list] : [...lastTree, ...list];
    //     this.lastSearch = search;
    //     this.loading = false;
    //     this.cdRef.markForCheck();
    //   });
  }

  onLoadMore() {
    // this.loadGqlDictionaryList(this.paginator!.currentPage + 1, this.currentSearch);
  }

  onSearch(search: string) {
    // this.currentSearch = search;
    // this.loadGqlDictionaryList(1, this.currentSearch);
  }
}
