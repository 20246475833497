export const employeeContractFormConfig = () => {
  return [
    {
      slug: 'group',
      elementConfiguration: {
        type: 'group',
        configuration: {
          fields: [
            {
              slug: 'startAt',
              elementConfiguration: {
                title: 'Start date',
                type: 'date',
                configuration:  {
                  between: false,
                  clearable: true,
                },
                validation: [
                  {
                    type: 'required',
                    text: 'Required field'
                  }
                ],
              }
            },
            {
              slug: 'endAt',
              elementConfiguration: {
                title: 'Termination date',
                type: 'date',
                configuration:  {
                  between: false,
                  clearable: true,
                },
                validation: [
                  {
                    type: 'required',
                    text: 'Required field'
                  }
                ],
              }
            },
            {
              slug: 'paymentType',
              elementConfiguration: {
                title: 'Contract type',
                type: 'select',
                configuration: {
                  clearable: true,
                  search: false,
                  dataList: [
                    {
                      id: 'rate',
                      key: 'rate',
                      label: 'Rate',
                    },
                    {
                      id: 'fix',
                      key: 'fix',
                      label: 'Fix',
                    },
                  ]
                },
                validation: [
                  {
                    type: 'required',
                    text: 'Required field'
                  }
                ],
              }
            },
            {
              slug: 'salary',
              elementConfiguration: {
                title: 'Contract amount',
                type: 'number',
                configuration: {
                  type: 'number',
                  between: false,
                  minValue: 0,
                  clearable: true,
                  prefix: '$ '
                },
                validation: [
                  {
                    type: 'required',
                    text: 'Required field'
                  }
                ],
              },
              showIf: [
                {
                  field: 'paymentType',
                  value: ['fix']
                }
              ]
            },
            {
              slug: 'hourRate',
              elementConfiguration: {
                title: 'Hour rate',
                type: 'number',
                configuration: {
                  type: 'number',
                  between: false,
                  minValue: 0,
                  clearable: true,
                  prefix: '$ '
                },
                validation: [
                  {
                    type: 'required',
                    text: 'Required field'
                  }
                ],
              },
              showIf: [
                {
                  field: 'paymentType',
                  value: ['rate']
                }
              ]
            },
          ]
        },
        validation: [
          {
            type: 'dateRange',
            text: 'Error field',
            relationFrom: 'startAt',
            relation: 'endAt'
          }
        ],
      }
    },
  ]
};
