import {Entity} from '@/app/core/entity/main.entity';

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export class SortModifire extends Entity {
  sortBy: string;
  sortDirection: SortDirection;
}
