import {projectBillingFormConfig} from '@/app/modals/form-config/project-billing.cfg';
import {DataList} from '@/app/entities/common/data-list.entity';

export const projectFormConfig = (projectList?: DataList[]) => {
  const projectField = projectList
    ? [
      {
        slug: 'parentNodeId',
        elementConfiguration: {
          title: 'Parent',
          type: 'select',
          configuration:  {
            dataList: projectList,
            clearable: true,
          },
          validation: [
            {
              type: 'required',
              text: 'Required field'
            }
          ],
        }
      },
    ]
    : [];

  return [
    {
      slug: 'code',
      elementConfiguration: {
        title: 'Code',
        type: 'text',
        configuration:  {
          type: 'text',
          clearable: true,
        },
        validation: [
          {
            type: 'required',
            text: 'Required field'
          }
        ],
      }
    },
    {
      slug: 'title',
      elementConfiguration: {
        title: 'Title',
        type: 'text',
        configuration:  {
          type: 'text',
          clearable: true,
        },
        validation: [
          {
            type: 'required',
            text: 'Required field'
          }
        ],
      }
    },
    {
      slug: 'description',
      elementConfiguration: {
        title: 'Description',
        type: 'textarea',
        configuration:  {
          clearable: true,
        },
        validation: [
          {
            type: 'required',
            text: 'Required field'
          }
        ],
      }
    },
    ...projectBillingFormConfig(),
    ...projectField
  ]
}
