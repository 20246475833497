import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormat',
  standalone: true
})
export class CurrencyFormatPipe implements PipeTransform {

  constructor() {}

  transform(value: number, currency: string = 'USD'): string {
    if (value == null || isNaN(+value)) return '';

    // TODO: take currency from service
    // const currency = 'RUB';
    const locale = currency === 'RUB' ? 'ru-RU' : 'en';

    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(value);
  }

}
